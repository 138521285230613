import * as React from 'react';
import Routes from './routes';
import { ThemeProvider, Theme } from '@mui/material/styles';
import { theme } from './theme';
import './assets/scss/main.scss';
import { QueryClient, QueryClientProvider } from 'react-query';
// import moment from 'moment-timezone';
// moment.tz.setDefault('Asia/Shanghai');

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient();
const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
