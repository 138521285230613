import React from 'react';
import { InputLabel, Tooltip, Grid, Box, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import HelpIcon from '@mui/icons-material/Help';

interface IFormTitle {
  title?: string;
  tipTitle?: string;
  shrinkTitle?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  title: {
    fontFamily: 'Avenir',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '1.5em',
    color: '#1C242A',
    paddingTop: 5,
    marginTop: 15,
    marginBottom: 8,
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px',
    },
  },
  toolTip: {
    marginTop: 15,
  },
  tipTitle: {
    fontSize: '15px',
  },
  helpIcon: {
    width: '18px',
    height: '18px',
    marginLeft: '5px',
  },
  soloTitle: {
    fontFamily: 'Avenir',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '1.5em',
    color: '#1C242A',
    padding: '5px 0 0',

    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    titleContainer: {},
  },
}));

const FormTitle: React.FC<IFormTitle> = (props: IFormTitle) => {
  const { title, tipTitle, shrinkTitle } = props;
  const midmatches = useMediaQuery('(max-width:960px)');

  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        alignContent="center"
        // justify="flex-start"
      >
        <Box
          style={{
            margin: '10px 0 5px 0',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {title && (
            <Grid>
              <InputLabel className={classes.title} shrink={shrinkTitle}>
                {title}
              </InputLabel>
            </Grid>
          )}
          <Grid className={classes.toolTip}>
            {tipTitle && (
              <Tooltip
                arrow
                title={<span className={classes.tipTitle}>{tipTitle}</span>}
                placement={midmatches ? 'bottom-end' : 'right'}
                enterTouchDelay={0}
                style={{ marginRight: 10 }}
                leaveTouchDelay={5000}
              >
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            )}
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default FormTitle;
