import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Box, Button, Theme } from '@mui/material';
import Page from '../../components/Page';
import Header from './Header';
import Results from './Results';
import agApi from '../../api';
import EditIcon from '@mui/icons-material/Edit';
import EditForex from './EditForex';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  newForexButton: {
    marginLeft: 'auto',
    marginRight: 0,
    marginTop: 10,
  },
  editButton: {
    textTransform: 'none',
  },
}));

const CustomerManagementList: React.FC = () => {
  const classes = useStyles();
  const { mutateAsync: getCurrencies } = agApi('getCurrencies');
  const [forexes, setForexes] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getForexes = async () => {
      const data = await getCurrencies();
      console.log(data);
      setForexes(data);
    };
    getForexes();
  }, []);
  console.log(forexes);
  return (
    <Page className={classes.root} title="Forex Management">
      <Container maxWidth={false}>
        <Header />
        {Object.keys(forexes).length > 0 && (
          <Results className={classes.results} data={forexes} />
        )}
        <Box className={classes.newForexButton}>
          <Button
            color="secondary"
            className={classes.editButton}
            variant="contained"
            onClick={() => {
              setOpen(true);
            }}
          >
            <EditIcon />
            添加新汇率
          </Button>
        </Box>
      </Container>
      <EditForex open={open} setOpen={setOpen} setForexes={setForexes} />
    </Page>
  );
};

export default CustomerManagementList;
