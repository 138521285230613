import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Grid, Button } from '@mui/material';

import FilterListIcon from '@mui/icons-material/FilterList';
import Search from './Search';
import Filter from './Filter';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  search: {
    flexGrow: 1,
    maxWidth: 480,
    flexBasis: 480,
  },
  filterButton: {
    marginLeft: 'auto',
  },
  filterIcon: {
    marginRight: theme.spacing(1),
  },
  headerActions: {
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  dateField: {},
}));

interface Props {
  className?: Record<string, unknown> | string;
  onFilter: () => void;
  onSearch: any;
  onDateChange: (date: string) => void;
}

const SearchBar: React.FC<Props> = ({
  onFilter,
  onSearch,
  onDateChange,
  className,
  ...rest
}: any) => {
  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false);

  const handleFilterOpen = () => {
    setOpenFilter(true);
  };

  const handleFilterClose = () => {
    setOpenFilter(false);
  };

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid item>
        <Search className={classes.search} onSearch={onSearch} />
      </Grid>
      {/* <Grid item className={classes.headerActions}>
        <Button
          className={classes.filterButton}
          color="primary"
          onClick={handleFilterOpen}
          size="small"
          variant="outlined"
        >
          <FilterListIcon className={classes.filterIcon} /> Show filters
        </Button>
      </Grid>
      <Filter
        onClose={handleFilterClose}
        onFilter={onFilter}
        open={openFilter}
      /> */}
    </Grid>
  );
};

// SearchBar.propTypes = {
//   className: PropTypes.string,
//   onFilter: PropTypes.func,
//   onSearch: PropTypes.func,
// };

export default SearchBar;
