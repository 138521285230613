/* eslint-disable camelcase */
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Container,
  Typography,
  Card,
  Divider,
  CardContent,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button,
  colors,
  Box,
  Theme,
} from '@mui/material';
import Page from '../../components/Page';
import { useParams } from 'react-router';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import agApi from '../../api';
import EditAgent from './EditAgent';
import { SessionContext } from '../../context/session';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    backgroundColor: colors.grey[300],
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  section: {
    '& + &': {
      marginTop: theme.spacing(5),
    },
  },
  tab: {
    textTransform: 'none',
    minWidth: 36,
  },
  buttonSpacing: {
    marginRight: 10,
  },
  table: {
    width: '400px',
  },
  editButton: {
    textTransform: 'none',
  },
  editAddBox: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  editButtonBox: {
    marginLeft: '0',
    marginTop: 10,
  },
  addButtonBox: {
    marginLeft: 'auto',
    marginRight: 0,
    marginTop: 10,
  },
}));

const Agent: React.FC = () => {
  const classes = useStyles();
  const [agent, setAgent] = useState(Object);
  const { id } = useParams<any>();
  const { mutateAsync: getAgentById } = agApi('getAgentById', { id });
  const [open, setOpen] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const sessionContext = useContext(SessionContext);

  useEffect(() => {
    let mounted = true;

    const fetchAgents = () => {
      getAgentById().then((data: any) => {
        if (mounted) {
          console.log(data);
          setAgent(data);
        }
      });
    };

    fetchAgents();
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      <Page className={classes.root} title="Agents Management">
        <Container maxWidth={false}>
          <div className={classes.section}>
            <Typography variant="overline">代理资料</Typography>
            <Divider className={classes.divider} />
            <Box className={classes.editAddBox}>
              {sessionContext.level === 9 && (
                <Box className={classes.editButtonBox}>
                  <Button
                    color="secondary"
                    className={classes.editButton}
                    variant="contained"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <EditIcon />
                    修改代理资料
                  </Button>
                </Box>
              )}
              <Box className={classes.addButtonBox}>
                <Button
                  color="secondary"
                  className={classes.editButton}
                  variant="contained"
                  onClick={() => {
                    setOpenNew(true);
                  }}
                >
                  <AddBoxIcon />
                  新增代理
                </Button>
              </Box>
            </Box>
            <Card>
              <CardContent>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>{agent?.agentId}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>户名</TableCell>
                      <TableCell>{agent?.agentName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>代理户口</TableCell>
                      <TableCell>{agent?.cqAccount}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>CG户口</TableCell>
                      <TableCell>{agent?.cgAccount}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>CG代号</TableCell>
                      <TableCell>{agent?.agcode}</TableCell>
                    </TableRow>
                    {sessionContext.level === 9 && (
                      <TableRow>
                        <TableCell>公司账号？</TableCell>
                        <TableCell>
                          {agent?.isCompanyAccount ? '是' : '否'}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>代理等级？</TableCell>
                      <TableCell>{agent?.level}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>上线cg代号？</TableCell>
                      <TableCell>{agent?.upAgcode}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>币种</TableCell>
                      <TableCell>{agent?.ccy}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>M/C种类</TableCell>
                      <TableCell>{agent?.mc}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>代理占成</TableCell>
                      <TableCell>{agent?.agentPercent}%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>代理佣金比</TableCell>
                      <TableCell>{agent?.agentCommission}%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>公司占成</TableCell>
                      <TableCell>{agent?.company1Percent}%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>SLE占成</TableCell>
                      <TableCell>{agent?.company2Percent}%</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>股东佣金差比</TableCell>
                      <TableCell>{agent?.commissionDiff}%</TableCell>
                    </TableRow>
                    {sessionContext.level === 9 && (
                      <TableRow>
                        <TableCell>联系方式</TableCell>
                        <TableCell>{agent?.contact}</TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>备注</TableCell>
                      <TableCell>{agent?.agentComment}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </div>
        </Container>
      </Page>
      {open && (
        <EditAgent
          open={open}
          setOpen={setOpen}
          updateFlag={true}
          agent={agent}
          setAgent={setAgent}
        />
      )}
      {openNew && (
        <EditAgent
          open={openNew}
          setOpen={setOpenNew}
          updateFlag={false}
          agent={{}}
          setAgent={setAgent}
        />
      )}
    </>
  );
};

export default Agent;
