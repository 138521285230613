const ExcelJS = require('exceljs');

const generateExcelFile = async (params: any) => {
  const { data: allData } = params;

  const workbook = new ExcelJS.Workbook();
  if (allData) {
    const data = allData;
    const worksheet = workbook.addWorksheet('代理列表');
    worksheet.columns = [
      { header: '户口', key: 'cqAccount', width: 13 },
      { header: 'CG户口', key: 'cgAccount', width: 13 },
      { header: '种类', key: 'mc', width: 13 },
      {
        header: '代理占成',
        key: 'agentPercent',
        width: 13,
        style: { numFmt: '#0.##%' },
      },
      {
        header: '代理佣金比',
        key: 'agentCommission',
        width: 13,
        style: { numFmt: '#0.##%' },
      },
      {
        header: '股东佣金差',
        key: 'commissionDiff',
        width: 13,
        style: { numFmt: '#0.##%' },
      },
      {
        header: '总余分',
        key: 'totalBalance',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '可用余分',
        key: 'balance',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '下线余分',
        key: 'memberBalance',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '现金余分',
        key: 'cashBalance',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
    ];
    worksheet.getRow(1).height = 30;
    ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1'].map((key) => {
      worksheet.getCell(key).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' },
        bgColor: { argb: 'D9D9D9' },
      };
      worksheet.getCell(key).border = {
        top: { style: 'medium' },
        left: { style: 'medium' },
        bottom: { style: 'medium' },
        right: { style: 'medium' },
      };
    });
    let startRowNo = 2;
    let emptyRowCount = 0;
    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const rowNo = i + 2 + emptyRowCount;

      let convertedRow = {};

      convertedRow = {
        cqAccount: row['cqAccount'],
        cgAccount: row['cgAccount'],
        mc: row['mc'],
        agentPercent: Number((row['agentPercent'] / 100).toFixed(4)),
        agentCommission: Number((row['agentCommission'] / 100).toFixed(4)),
        commissionDiff: Number((row['commissionDiff'] / 100).toFixed(4)),
        totalBalance: row['totalBalance'],
        balance: row.cgAccount === 'rmb' ? row.balance : '',
        memberBalance: row.cgAccount === 'rmb' ? row.memberBalance : '',
        cashBalance:
          row.mc === 'P'
            ? Number(
                ((1 - row.agentPercent / 100) * row.totalBalance).toFixed(0)
              )
            : row.mc === 'C'
            ? row.totalBalance
            : '',
      };

      const addedRow = worksheet.addRow(convertedRow);
      let fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E2EFDA' },
        bgColor: { argb: 'E2EFDA' },
      };
      let border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      if (row['ccy'] === 'PHP') {
        fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'f4f7c8' },
          bgColor: { argb: 'f4f7c8' },
        };
        border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      if (row['ccy'] === 'RMB') {
        fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'c8e7f7' },
          bgColor: { argb: 'c8e7f7' },
        };
        border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      const arr = Array.from({ length: 10 }, (_, j) => j + 1);
      arr.map((x) => (worksheet.getCell(worksheet.rowCount, x).fill = fill));
      arr.map(
        (x) => (worksheet.getCell(worksheet.rowCount, x).border = border)
      );
      if (i < data.length - 1 && data[i]['ccy'] !== data[i + 1]['ccy']) {
        const suSumRow = {
          ccy: '',
          mc: '合计：',
          agentName: '',
          cqAccount: '',
          cgAccount: '',
          winlose: { formula: `SUM(F${startRowNo}:F${rowNo})` },
          wash: { formula: `SUM(G${startRowNo}:G${rowNo})` },
          tip: { formula: `SUM(H${startRowNo}:H${rowNo})` },
          agentPercent: '',
          company1Percent: '',
          company2Percent: '',
          agentCommission: '',
          agentCommissionAmount: { formula: `SUM(M${startRowNo}:M${rowNo})` },
          commissionDiff: '',
          commissionDiffAmount: { formula: `SUM(O${startRowNo}:O${rowNo})` },
          actualSettle: { formula: `SUM(P${startRowNo}:P${rowNo})` },
          company1Amount: { formula: `SUM(Q${startRowNo}:Q${rowNo})` },
          company2Amount: { formula: `SUM(R${startRowNo}:R${rowNo})` },
          settleStatus: '',
        };
        worksheet.addRow(suSumRow);
        emptyRowCount += 1;
        startRowNo = rowNo + 2;
      }
    }

    // summary row
    const count = data.length;
    const sumRowNo = count + 1 + emptyRowCount;
    const sumRow = {
      mc: '合计：',
      totalBalance: { formula: `SUM(G${startRowNo}:G${sumRowNo})` },
      balance: { formula: `SUM(H${startRowNo}:H${sumRowNo})` },
      memberBalance: { formula: `SUM(I${startRowNo}:I${sumRowNo})` },
      cashBalance: { formula: `SUM(J${startRowNo}:J${sumRowNo})` },
    };
    const addedSum = worksheet.addRow(sumRow);
  }

  // write to a new buffer
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer]);
  const objectURL = URL.createObjectURL(blob);
  // Auto download
  const link = document.createElement('a');
  link.href = objectURL;
  link.setAttribute('download', '代理列表.xlsx');
  // Append to html link element page
  document.body.appendChild(link);
  // Start download
  link.click();
  // Clean up and remove the link
  link?.parentNode.removeChild(link);
};

export default generateExcelFile;
