import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Container,
  Box,
  Button,
  TextField,
  CircularProgress,
  Typography,
  Divider,
  Card,
  Autocomplete,
  Grid,
} from '@mui/material';
import DateTimePicker from '../../components/DateTimePicker';
import Page from '../../components/Page';
import Header from './Header';
import agApi from '../../api';
import { useHistoryState } from '../../utils/useHistoryState';
import moment from 'moment-timezone';
import generateExcelFile from './generateExcelFile';
import ReportList from './ReportList';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  results: {
    marginTop: 20,
  },
  editButton: {
    textTransform: 'none',
    marginLeft: '10px',
  },
  circularProgress: {
    marginLeft: '20px',
  },
  card: {
    padding: 20,
    marginTop: 5,
  },
  autoCompleteRoot: {
    marginLeft: 20,
    marginTop: 20,
    padding: 0,
  },
  inputRoot: {
    border: '0px',
    borderRadius: '5px',
    borderColor: '#ffffff',
    backgroundColor: '#ffffff',
    marginRight: 20,
  },
  inputError: {
    color: '#cd2d2d',
    fontSize: '16px',
    margin: '22px 0 10px 5px',
    '&:before': {
      content: '',
      display: 'inline-block',
      width: '30px',
      height: '30px',
      backgroundimage: "url('../../asset/img/error.svg')",
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      marginRight: '15px',
      marginBottom: '-6px',
    },
  },
}));

const Downloading = () => {
  const classes = useStyles();
  return (
    <Box
      position="relative"
      display="inline-flex"
      className={classes.circularProgress}
      style={{ marginTop: 20, marginLeft: 20 }}
    >
      <CircularProgress />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          生成中
        </Typography>
      </Box>
    </Box>
  );
};

const Reports: React.FC = () => {
  const classes = useStyles();
  const { mutateAsync: winloseReport } = agApi('winloseReport');
  const { mutateAsync: getAgents } = agApi('getAgents');

  const inputAgent = useRef(null);
  const [startDate, setStartDate] = useHistoryState(
    'cashStartDate',
    // eslint-disable-next-line newline-per-chained-call
    moment()
      .subtract(12, 'hours')
      .subtract(1, 'days')
      .startOf('day')
      .add(12, 'hours')
      .format('YYYY-MM-DD HH:mm:ss')
  );
  const [endDate, setEndDate] = useHistoryState(
    'cashEndDate',
    moment()
      .subtract(12, 'hours')
      .subtract(1, 'days')
      .endOf('day')
      .add(12, 'hours')
      .format('YYYY-MM-DD HH:mm:ss')
  );

  const [downloading, setDownloading] = useState(false);
  const [downloadingThisWeek, setDownloadingThisWeek] = useState(false);
  const [
    downloadingThisWeekWithoutSettle,
    setDownloadingThisWeekWithoutSettle,
  ] = useState(false);
  const [downloadingLastWeek, setDownloadingLastWeek] = useState(false);
  const [
    downloadingLastWeekWithoutSettle,
    setDownloadingLastWeekWithoutSettle,
  ] = useState(false);
  const [downloadingThisMonth, setDownloadingThisMonth] = useState(false);
  const [
    downloadingThisMonthWithoutSettle,
    setDownloadingThisMonthWithoutSettle,
  ] = useState(false);
  const [downloadingLastMonth, setDownloadingLastMonth] = useState(false);
  const [
    downloadingLastMonthWithoutSettle,
    setDownloadingLastMonthWithoutSettle,
  ] = useState(false);
  const [downloadingYesterday, setDownloadingYesterday] = useState(false);
  const [
    downloadingYesterdayWithoutSettle,
    setDownloadingYesterdayWithoutSettle,
  ] = useState(false);
  const [downloadingToday, setDownloadingToday] = useState(false);
  const [downloadingTodayWithoutSettle, setDownloadingTodayWithoutSettle] =
    useState(false);
  const [downloading11, setDownloading11] = useState(false);

  const [agents, setAgents] = useState([{}]);
  const [inputAgentError, setInputAgentError] = useState<undefined | boolean>(
    undefined
  );
  const [errorMessage, setErrorMessage] = useState('');

  const [rawData, setRawData] = useState([]);
  const [formatedData, setFormatedData] = useState<any>([]);

  const formatData = (data: any) => {
    const newData = data.map((row: any, index: number) => {
      const winlose = Number((row['winlose'] / 10000).toFixed(4));
      const wash = Number((row['wash'] / 10000).toFixed(4));
      const tip = Number((row['tip'] / 10000).toFixed(4));
      const agentCommissionAmount = Number(
        (((row['wash'] / 10000) * row['agentCommission']) / 100).toFixed(4)
      );
      const commissionDiffAmount = Number(
        (((row['wash'] / 10000) * row['commissionDiff']) / 100).toFixed(4)
      );
      return {
        id: index,
        ccy: row.ccy,
        mc: row['mc'] === 'P' ? '配码上分' : row['mc'],
        agentName: row.agentName,
        cqAccount: row.cqAccount,
        cgAccount: row.cgAccount,
        winlose,
        wash,
        tip,
        agentPercent: row['agentPercent'] + '%',
        company1Percent: row['company1Percent'] + '%',
        company2Percent: row['company2Percent'] + '%',
        agentCommission: row['agentCommission'] + '%',
        agentCommissionAmount,
        commissionDiff: row['commissionDiff'] + '%',
        commissionDiffAmount,
        actualSettle:
          row['mc'] === 'C'
            ? (
                (winlose * row['agentPercent']) / 100 +
                agentCommissionAmount +
                commissionDiffAmount
              ).toFixed(4)
            : row['mc'] === 'M'
            ? (
                -((winlose * (1 - row['agentPercent'])) / 100) -
                agentCommissionAmount -
                commissionDiffAmount
              ).toFixed(4)
            : agentCommissionAmount,
        company1Amount: (
          (winlose * row['company1Percent']) / 100 -
          agentCommissionAmount -
          commissionDiffAmount
        ).toFixed(4),
        company2Amount: (
          (winlose * row['company2Percent']) / 100 +
          tip
        ).toFixed(4),
        settleStatus:
          row['agentHasSettled'] === 1
            ? `已结算-${row['settleType'] === '' ? '未交收' : '已交收'}`
            : row['agentHasSettled'] === 0
            ? '未结算'
            : '',
        settlePeriod:
          row['agentHasSettled'] === 1 || row['agentHasSettled'] === 0
            ? `${moment(row['startDate']).format(
                'YYYY-MM-DD HH:mm:ss'
              )} - ${moment(row['endDate']).format('YYYY-MM-DD HH:mm:ss')}`
            : '',
      };
    });
    return newData;
  };

  useEffect(() => {
    let mounted = true;

    const fetchAgents = () => {
      getAgents().then((data: any) => {
        if (mounted) {
          setAgents(data);
        }
      });
    };

    fetchAgents();

    return () => {
      mounted = false;
    };
  }, []);

  const getReportData = async () => {
    setDownloading(true);
    setErrorMessage('');
    try {
      const cgAccount = inputAgent.current.value;
      const selected: any = agents.find(
        (agent: any) => agent.cgAccount === cgAccount
      );
      const postData = {
        startDate: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        includeSettle: true,
        includeMonthlySum: false,
      };
      if (selected) {
        postData['agcode'] = selected.agcode;
      }
      const data = await winloseReport(postData);
      setRawData(data);
      setFormatedData(formatData(data?.data));
    } catch (err) {
      setErrorMessage(err.message);
    }

    setDownloading(false);
  };

  const downloadReport = async (
    start: string,
    end: string,
    includeSettle: boolean,
    includeMonthlySum: boolean = false
  ) => {
    const cgAccount = inputAgent.current.value;
    const selected: any = agents.find(
      (agent: any) => agent.cgAccount === cgAccount
    );

    const postData = {
      startDate: start,
      endDate: end,
      includeSettle,
      includeMonthlySum,
    };
    if (selected) {
      postData['agcode'] = selected.agcode;
    }
    const data = await winloseReport(postData);
    if (data?.data && !includeSettle) {
      const array = data.data;
      const keys = ['cgAccount', 'agentPercent', 'agentCommission'];
      const combinedData = Object.values(
        array.reduce((r: any, o: any) => {
          const key = keys.map((k) => o[k]).join('|');
          if (!r[key]) r[key] = { ...o, wash: 0, winlose: 0 };
          r[key].wash += o.wash;
          r[key].winlose += o.winlose;
          return r;
        }, {})
      );
      data.data = combinedData;
    }

    setRawData(data);

    setFormatedData(formatData(data?.data));
  };

  const getReportDataToday = async (includeSettle: boolean) => {
    if (includeSettle) {
      setDownloadingToday(true);
    } else {
      setDownloadingTodayWithoutSettle(true);
    }

    setErrorMessage('');
    try {
      const start = moment()
        .subtract(12, 'hours')
        .startOf('day')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      const end = moment()
        .subtract(12, 'hours')
        .endOf('day')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      await downloadReport(start, end, includeSettle);
    } catch (err) {
      setErrorMessage(err.message);
    }
    if (includeSettle) {
      setDownloadingToday(false);
    } else {
      setDownloadingTodayWithoutSettle(false);
    }
  };

  const getReportDataYesterday = async (includeSettle: boolean) => {
    if (includeSettle) {
      setDownloadingYesterday(true);
    } else {
      setDownloadingYesterdayWithoutSettle(true);
    }

    setErrorMessage('');
    try {
      const start = moment()
        .subtract(12, 'hours')
        .subtract(1, 'days')
        .startOf('day')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      const end = moment()
        .subtract(12, 'hours')
        .subtract(1, 'days')
        .endOf('day')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      if (includeSettle) {
        await downloadReport(start, end, includeSettle, false);
      } else {
        await downloadReport(start, end, includeSettle, true);
      }
    } catch (err) {
      setErrorMessage(err.message);
    }
    if (includeSettle) {
      setDownloadingYesterday(false);
    } else {
      setDownloadingYesterdayWithoutSettle(false);
    }
  };

  const getReportDataThisWeek = async (includeSettle: boolean) => {
    if (includeSettle) {
      setDownloadingThisWeek(true);
    } else {
      setDownloadingThisWeekWithoutSettle(true);
    }

    setErrorMessage('');
    try {
      const start = moment()
        .subtract(12, 'hours')
        .startOf('isoWeek')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      const end = moment()
        .subtract(12, 'hours')
        .endOf('isoWeek')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      await downloadReport(start, end, includeSettle);
    } catch (err) {
      setErrorMessage(err.message);
    }
    if (includeSettle) {
      setDownloadingThisWeek(false);
    } else {
      setDownloadingThisWeekWithoutSettle(false);
    }
  };

  const getReportDataLastWeek = async (includeSettle: boolean) => {
    if (includeSettle) {
      setDownloadingLastWeek(true);
    } else {
      setDownloadingLastWeekWithoutSettle(true);
    }

    setErrorMessage('');
    try {
      const start = moment()
        .subtract(12, 'hours')
        .subtract(1, 'week')
        .startOf('isoWeek')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      const end = moment()
        .subtract(12, 'hours')
        .subtract(1, 'week')
        .endOf('isoWeek')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      await downloadReport(start, end, includeSettle);
    } catch (err) {
      setErrorMessage(err.message);
    }
    if (includeSettle) {
      setDownloadingLastWeek(false);
    } else {
      setDownloadingLastWeekWithoutSettle(false);
    }
  };

  // get first Monday
  const getFirstMonday = (dateString: string): string => {
    const today = moment(dateString);
    let date = moment()
      .set('year', today.year())
      .set('month', today.month())
      .set('date', 1)
      .isoWeekday(8);
    if (date.date() > 7) {
      date = date.isoWeekday(-6);
    }

    const firstMonday = date.startOf('isoWeek').add(12, 'hours');
    if (today < firstMonday) {
      const todayOfLastMonth = today
        .subtract(1, 'month')
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
      return getFirstMonday(todayOfLastMonth);
    }

    return firstMonday.format('YYYY-MM-DD HH:mm:ss');
  };

  // get first Monday end
  const getNextMonthFirstMonday = (dateString: string): string => {
    const today = moment(dateString).add(1, 'month');
    let date = moment()
      .set('year', today.year())
      .set('month', today.month())
      .set('date', 1)
      .isoWeekday(8);
    if (date.date() > 7) {
      date = date.isoWeekday(-6);
    }

    const firstMonday = date
      .startOf('isoWeek')
      .add(12, 'hours')
      .subtract(1, 'second');
    return firstMonday.format('YYYY-MM-DD HH:mm:ss');
  };

  const getReportDataThisMonth = async (includeSettle: boolean) => {
    if (includeSettle) {
      setDownloadingThisMonth(true);
    } else {
      setDownloadingThisMonthWithoutSettle(true);
    }

    setErrorMessage('');
    try {
      const start = getFirstMonday(moment().format('YYYY-MM-DD HH:mm:ss'));
      const end = getNextMonthFirstMonday(start);
      await downloadReport(start, end, includeSettle);
    } catch (err) {
      setErrorMessage(err.message);
    }
    if (includeSettle) {
      setDownloadingThisMonth(false);
    } else {
      setDownloadingThisMonthWithoutSettle(false);
    }
  };

  const getReportDataLastMonth = async (includeSettle: boolean) => {
    if (includeSettle) {
      setDownloadingLastMonth(true);
    } else {
      setDownloadingLastMonthWithoutSettle(true);
    }

    setErrorMessage('');
    try {
      const firstMonday = getFirstMonday(
        moment().format('YYYY-MM-DD HH:mm:ss')
      );
      const end = moment(firstMonday)
        .subtract(1, 'second')
        .format('YYYY-MM-DD HH:mm:ss');
      const start = getFirstMonday(
        moment(end)
          .subtract(1, 'month')
          .endOf('month')
          .format('YYYY-MM-DD HH:mm:ss')
      );
      await downloadReport(start, end, includeSettle);
    } catch (err) {
      setErrorMessage(err.message);
    }
    if (includeSettle) {
      setDownloadingLastMonth(false);
    } else {
      setDownloadingLastMonthWithoutSettle(false);
    }
  };

  const getReportDataWithoutSettle = async () => {
    setDownloading11(true);
    setErrorMessage('');
    try {
      const cgAccount = inputAgent.current.value;
      const selected: any = agents.find(
        (agent: any) => agent.cgAccount === cgAccount
      );
      const postData = {
        startDate: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        includeSettle: false,
        includeMonthlySum: false,
      };
      if (selected) {
        postData['agcode'] = selected.agcode;
      }
      const data = await winloseReport(postData);
      setRawData(data);
      setFormatedData(formatData(data?.data));
    } catch (err) {
      setErrorMessage(err.message);
    }

    setDownloading11(false);
  };

  const handleChangeAgent = (event: any, newValue: any) => {
    console.log(newValue);
  };

  const downloadExcel = () => {
    generateExcelFile({
      startDate,
      endDate,
      data: rawData,
      includeMonthlySum: false,
    });
  };

  return (
    <>
      <Page className={classes.root} title="Report Management">
        <Container maxWidth={false}>
          <Header />
          <Card className={classes.card}>
            <Grid container spacing={2} mt={0}>
              <Autocomplete
                // value={agcode}
                freeSolo
                options={agents}
                getOptionLabel={(option: any) =>
                  option.cgAccount ? option.cgAccount : ''
                }
                disableClearable
                classes={{
                  root: classes.autoCompleteRoot,
                  inputRoot: classes.inputRoot,
                }}
                autoHighlight={true}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    variant={'outlined'}
                    placeholder={'输入cg代码'}
                    inputRef={inputAgent}
                    InputProps={{ ...params.InputProps, type: 'search' }}
                    style={{ width: 150 }}
                  />
                )}
                onChange={handleChangeAgent}
              />
              {inputAgentError ? (
                <div className={classes.inputError}>请输入正确的cg户口</div>
              ) : null}

              <Box style={{ marginLeft: 20, marginTop: 20 }}>
                <DateTimePicker
                  label="开始日期"
                  value={startDate}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  onChange={(newValue: any) => {
                    setStartDate(newValue);
                  }}
                />
              </Box>
              <Box style={{ marginLeft: 20, marginTop: 20 }}>
                <DateTimePicker
                  label="结束日期"
                  value={endDate}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  onChange={(newValue: any) => {
                    setEndDate(newValue);
                  }}
                />
              </Box>
              {!downloading ? (
                <Button
                  color="primary"
                  className={classes.editButton}
                  variant="contained"
                  onClick={getReportData}
                  style={{ marginLeft: 20, marginTop: 20 }}
                >
                  获取数据
                </Button>
              ) : (
                <Downloading />
              )}
              {!downloading11 ? (
                <Button
                  color="primary"
                  className={classes.editButton}
                  variant="contained"
                  onClick={getReportDataWithoutSettle}
                  style={{ marginLeft: 20, marginTop: 20 }}
                >
                  获取数据(不含结算)
                </Button>
              ) : (
                <Downloading />
              )}
              <Button
                color="warning"
                className={classes.editButton}
                variant="contained"
                onClick={downloadExcel}
                style={{ marginLeft: 20, marginTop: 20 }}
              >
                下载报表
              </Button>
            </Grid>
            <Grid container mt={2}>
              <Grid item xs={12} mt={2}>
                {!downloadingToday ? (
                  <Button
                    color="secondary"
                    className={classes.editButton}
                    variant="contained"
                    onClick={() => getReportDataToday(true)}
                    style={{ marginLeft: 5 }}
                  >
                    今日报表
                  </Button>
                ) : (
                  <Downloading />
                )}
                {!downloadingTodayWithoutSettle ? (
                  <Button
                    color="secondary"
                    className={classes.editButton}
                    variant="contained"
                    onClick={() => getReportDataToday(false)}
                    style={{ marginLeft: 20 }}
                  >
                    今日报表(不含结算)
                  </Button>
                ) : (
                  <Downloading />
                )}
              </Grid>
              <Grid item xs={12} mt={2}>
                {!downloadingYesterday ? (
                  <Button
                    color="secondary"
                    className={classes.editButton}
                    variant="contained"
                    onClick={() => getReportDataYesterday(true)}
                    style={{ marginLeft: 5 }}
                  >
                    昨日报表
                  </Button>
                ) : (
                  <Downloading />
                )}
                {!downloadingYesterdayWithoutSettle ? (
                  <Button
                    color="secondary"
                    className={classes.editButton}
                    variant="contained"
                    onClick={() => getReportDataYesterday(false)}
                    style={{ marginLeft: 20 }}
                  >
                    昨日报表(不含结算)
                  </Button>
                ) : (
                  <Downloading />
                )}
              </Grid>
              <Grid item xs={12} mt={2}>
                {!downloadingThisWeek ? (
                  <Button
                    color="secondary"
                    className={classes.editButton}
                    variant="contained"
                    onClick={() => getReportDataThisWeek(true)}
                    style={{ marginLeft: 5 }}
                  >
                    本周报表
                  </Button>
                ) : (
                  <Downloading />
                )}
                {!downloadingThisWeekWithoutSettle ? (
                  <Button
                    color="secondary"
                    className={classes.editButton}
                    variant="contained"
                    onClick={() => getReportDataThisWeek(false)}
                    style={{ marginLeft: 20 }}
                  >
                    本周报表(不含结算)
                  </Button>
                ) : (
                  <Downloading />
                )}
              </Grid>
              <Grid item xs={12} mt={2}>
                {!downloadingLastWeek ? (
                  <Button
                    color="secondary"
                    className={classes.editButton}
                    variant="contained"
                    onClick={() => getReportDataLastWeek(true)}
                    style={{ marginLeft: 5 }}
                  >
                    上周报表
                  </Button>
                ) : (
                  <Downloading />
                )}
                {!downloadingLastWeekWithoutSettle ? (
                  <Button
                    color="secondary"
                    className={classes.editButton}
                    variant="contained"
                    onClick={() => getReportDataLastWeek(false)}
                    style={{ marginLeft: 20 }}
                  >
                    上周报表(不含结算)
                  </Button>
                ) : (
                  <Downloading />
                )}
              </Grid>
              <Grid item xs={12} mt={2}>
                {!downloadingThisMonth ? (
                  <Button
                    color="secondary"
                    className={classes.editButton}
                    variant="contained"
                    onClick={() => getReportDataThisMonth(true)}
                    style={{ marginLeft: 5 }}
                  >
                    本月报表
                  </Button>
                ) : (
                  <Downloading />
                )}
                {!downloadingThisMonthWithoutSettle ? (
                  <Button
                    color="secondary"
                    className={classes.editButton}
                    variant="contained"
                    onClick={() => getReportDataThisMonth(false)}
                    style={{ marginLeft: 20 }}
                  >
                    本月报表(不含结算)
                  </Button>
                ) : (
                  <Downloading />
                )}
              </Grid>
              <Grid item xs={12} mt={2}>
                {!downloadingLastMonth ? (
                  <Button
                    color="secondary"
                    className={classes.editButton}
                    variant="contained"
                    onClick={() => getReportDataLastMonth(true)}
                    style={{ marginLeft: 5 }}
                  >
                    上月报表
                  </Button>
                ) : (
                  <Downloading />
                )}
                {!downloadingLastMonthWithoutSettle ? (
                  <Button
                    color="secondary"
                    className={classes.editButton}
                    variant="contained"
                    onClick={() => getReportDataLastMonth(false)}
                    style={{ marginLeft: 20 }}
                  >
                    上月报表(不含结算)
                  </Button>
                ) : (
                  <Downloading />
                )}
              </Grid>
            </Grid>
            <span style={{ color: 'red' }}>
              {errorMessage ? errorMessage : ''}
            </span>
          </Card>
          {formatedData.length > 0 && (
            <Card className={classes.card}>
              <ReportList data={formatedData} />
            </Card>
          )}
        </Container>
      </Page>
    </>
  );
};

export default Reports;
