import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Container,
  Box,
  Button,
  Theme,
  Typography,
  CircularProgress,
  Grid,
  Card,
} from '@mui/material';
import Page from '../../components/Page';
import SearchBar from '../../components/SearchBar';
import Header from './Header';
import agApi from '../../api';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RefreshIcon from '@mui/icons-material/Refresh';
import ImportAgent from './ImportAgent';
import AgentList from './AgentList';
import generateExcelFile from './generateExcelFile';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  searchBox: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  addButtonBox: {
    width: '200px',
    marginLeft: 'auto',
    marginRight: 0,
    marginTop: 10,
  },
  addButton: {
    textTransform: 'none',
  },
  circularProgress: {
    marginRight: 40,
    marginTop: 5,
  },
  card: {
    marginTop: 5,
    padding: 20,
  },
}));

const Downloading = () => {
  const classes = useStyles();
  return (
    <Box
      position="relative"
      display="inline-flex"
      className={classes.circularProgress}
      style={{ marginTop: 20, marginLeft: 20 }}
    >
      <CircularProgress />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          更新中
        </Typography>
      </Box>
    </Box>
  );
};

const Agents: React.FC = () => {
  const classes = useStyles();
  const { mutateAsync: getAgents } = agApi('getAgents');
  const { mutateAsync: searchAgent } = agApi('searchAgent');
  const { mutateAsync: refreshBalances } = agApi('refreshBalances');
  const [agents, setAgents] = useState([]);
  const [formatedData, setFormatedData] = useState([]);
  const [open, setOpen] = useState(false);
  const [processing, setProcessing] = useState(false);

  const formatData = (data: any) => {
    const newData = data.map((row: any) => {
      return {
        id: row.agentId,
        cqAccount: row.cqAccount,
        cgAccount: row.cgAccount,
        mc: row.mc,
        agentPercent: row.agentPercent + '%',
        agentCommission: row.agentCommission + '%',
        commissionDiff: row.commissionDiff,
        totalBalance: row.totalBalance,
        balance: row.cgAccount === 'rmb' ? row.balance : '',
        memberBalance: row.cgAccount === 'rmb' ? row.memberBalance : '',
        cashBalance:
          row.mc === 'P'
            ? Number(
                ((1 - row.agentPercent / 100) * row.totalBalance).toFixed(0)
              )
            : row.mc === 'C'
            ? row.totalBalance
            : '',
      };
    });
    return newData;
  };

  const handleFilter = () => {
    // todo
  };

  const handleSearch = async (searchStr: string) => {
    try {
      const result = await searchAgent({ keyword: searchStr });

      if (result) {
        setAgents(result);
        setFormatedData(formatData(result));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onDateChange = async (date: string) => {
    //
  };

  useEffect(() => {
    let mounted = true;

    const fetchAgents = () => {
      getAgents().then((data: any) => {
        if (mounted) {
          setAgents(data);
          setFormatedData(formatData(data));
        }
      });
    };

    fetchAgents();

    return () => {
      mounted = false;
    };
  }, []);

  const searchClassName = {};

  const handleRefreshBalances = async () => {
    setProcessing(true);
    await refreshBalances();
    const data = await getAgents();
    setAgents(data);
    setFormatedData(formatData(data));
    setProcessing(false);
  };

  return (
    <>
      <Page className={classes.root} title="Agents Management">
        <Container maxWidth={false}>
          <Header />
          <Card className={classes.card}>
            <Grid container spacing={2} mt={0}>
              <Box style={{ marginLeft: 20, marginTop: 10 }}>
                <SearchBar
                  className={searchClassName}
                  onFilter={handleFilter}
                  onSearch={handleSearch}
                  onDateChange={onDateChange}
                />
              </Box>
              {!processing ? (
                <Button
                  color="warning"
                  className={classes.addButton}
                  variant="contained"
                  onClick={handleRefreshBalances}
                  style={{ marginLeft: 20, marginTop: 10 }}
                >
                  <RefreshIcon />
                  更新所有余分
                </Button>
              ) : (
                <Downloading />
              )}
              <Button
                color="secondary"
                className={classes.addButton}
                variant="contained"
                onClick={() => {
                  setOpen(true);
                }}
                style={{ marginLeft: 20, marginTop: 10 }}
              >
                <AddBoxIcon />
                从CG导入代理
              </Button>

              <Button
                color="success"
                className={classes.addButton}
                variant="contained"
                onClick={() => {
                  generateExcelFile({ data: agents });
                }}
                style={{ marginLeft: 20, marginTop: 10 }}
              >
                <AddBoxIcon />
                导出报表
              </Button>
            </Grid>
          </Card>
          <Card className={classes.card}>
            {formatedData.length > 0 && <AgentList data={formatedData} />}
          </Card>
        </Container>
      </Page>
      <ImportAgent open={open} setOpen={setOpen} />
    </>
  );
};

export default Agents;
