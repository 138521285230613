import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Container,
  Box,
  Button,
  Theme,
  TextField,
  CircularProgress,
  Typography,
  Card,
  Autocomplete,
  Grid,
} from '@mui/material';
import DateTimePicker from '../../components/DateTimePicker';
import Page from '../../components/Page';
import Header from './Header';
import agApi from '../../api';
import { useHistoryState } from '../../utils/useHistoryState';
import moment from 'moment-timezone';
import generateExcelFile from './generateExcelFile';
import CashDetail from './CashDetail';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  editButton: {
    textTransform: 'none',
    marginLeft: '10px',
  },
  circularProgress: {
    marginLeft: '20px',
  },
  card: {
    padding: 20,
    marginTop: 5,
  },
  autoCompleteRoot: {
    marginTop: 20,
    marginLeft: 20,
    padding: 0,
  },
  inputRoot: {
    border: '0px',
    borderRadius: '5px',
    borderColor: '#ffffff',
    backgroundColor: '#ffffff',
    marginRight: 20,
  },
  inputError: {
    color: '#cd2d2d',
    fontSize: '16px',
    margin: '22px 0 10px 5px',
    '&:before': {
      content: '',
      display: 'inline-block',
      width: '30px',
      height: '30px',
      backgroundimage: "url('../../asset/img/error.svg')",
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      marginRight: '15px',
      marginBottom: '-6px',
    },
  },
}));

const Downloading = () => {
  const classes = useStyles();
  return (
    <Box
      position="relative"
      display="inline-flex"
      className={classes.circularProgress}
      style={{ marginTop: 20, marginLeft: 20 }}
    >
      <CircularProgress />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          下载数据中
        </Typography>
      </Box>
    </Box>
  );
};

const Cash: React.FC = () => {
  const classes = useStyles();
  const { mutateAsync: getAgents } = agApi('getAgents');
  const { mutateAsync: getConfigs } = agApi('getConfigs');
  const { mutateAsync: getCashs } = agApi('getCashs');
  const { mutateAsync: forceUpdateCashs } = agApi('forceUpdateCashs');
  const { mutateAsync: updateCashs } = agApi('updateCashs');
  const { mutateAsync: searchCashs } = agApi('searchCashs');
  const [agcode, setAgcode] = useState();
  const inputAgent = useRef(null);

  const [startDate, setStartDate] = useHistoryState(
    'cashStartDate',
    // eslint-disable-next-line newline-per-chained-call
    moment()
      .subtract(12, 'hours')
      .subtract(1, 'days')
      .startOf('day')
      .add(12, 'hours')
      .format('YYYY-MM-DD HH:mm:ss')
  );

  const [endDate, setEndDate] = useHistoryState(
    'cashEndDate',
    // eslint-disable-next-line newline-per-chained-call
    moment()
      .subtract(12, 'hours')
      .subtract(1, 'days')
      .endOf('day')
      .add(12, 'hours')
      .format('YYYY-MM-DD HH:mm:ss')
  );

  const [downloading, setDownloading] = useState(false);
  const [downloading2, setDownloading2] = useState(false);
  const [downloadingToday, setDownloadingToday] = useState(false);
  const [downloadingYesterday, setDownloadingYesterday] = useState(false);
  const [downloadingThisWeek, setDownloadingThisWeek] = useState(false);
  const [downloadingLastWeek, setDownloadingLastWeek] = useState(false);
  const [agents, setAgents] = useState([{}]);
  const [inputAgentError, setInputAgentError] = useState<undefined | boolean>(
    undefined
  );
  const [configs, setConfigs] = useState([{}]);

  const [cashData, setCashData] = useState<any>([]);
  const [cashList, setCashList] = useState<any>([]);
  const [withdrawFee, setWithdrawFee] = useState<number>(0);

  const [visibleRows, setVisibleRows] = React.useState<any[]>();
  const [errorMessage, setErrorMessage] = useState('');

  const formatData = (data: any) => {
    const formatedData = data.map((cData: any) => {
      const newWithdrawFee =
        data.createdAt < '2023-09-07 18:00:00' ? 0.7 : withdrawFee;
      return {
        id: cData.cashId,
        createdAt: moment(cData.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        mc: cData.mc === 'P' ? '配码上分' : cData.mc,
        agentPercent: cData.mc === 'P' ? cData.agentPercent + '%' : '',
        cgAccount: cData.cgAccount,
        action:
          cData.action === 'deposit'
            ? '上分'
            : cData.action === 'withdraw'
            ? '下分'
            : '结算',
        operator: cData.createdBy,
        ccy: cData.ccy,
        amount: cData.amount,
        handlingFee:
          cData.handlingFee || cData.handlingFee === 0
            ? cData.handlingFee
            : cData.amount < 0
            ? ((-cData.amount * newWithdrawFee) / 100).toFixed(0)
            : '',
        preWithdraw: cData.preWithdraw,
        actualSettle: cData.actualSettle,
        transferAccount: cData.transferAccount,
        comment: cData.comment,
      };
    });
    return formatedData;
  };

  const downloadReport = async (start: string, end: string) => {
    const cgAccount = inputAgent.current.value;
    const selected: any = agents.find(
      (agent: any) => agent.cgAccount === cgAccount
    );

    const postData = {
      startDate: start,
      endDate: end,
    };
    if (selected) {
      postData['agcode'] = selected.agcode;
    }
    const data = await searchCashs(postData);

    const formatedData = formatData(data);
    generateExcelFile({ configs, data: formatedData });
  };

  const getReportDataToday = async () => {
    setDownloadingToday(true);

    setErrorMessage('');
    try {
      const start = moment()
        .subtract(12, 'hours')
        .startOf('day')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      const end = moment()
        .subtract(12, 'hours')
        .endOf('day')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      await downloadReport(start, end);
    } catch (err) {
      setErrorMessage(err.message);
    }
    setDownloadingToday(false);
  };

  const getReportDataYesterday = async () => {
    setDownloadingYesterday(true);

    setErrorMessage('');
    try {
      const start = moment()
        .subtract(12, 'hours')
        .subtract(1, 'days')
        .startOf('day')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      const end = moment()
        .subtract(12, 'hours')
        .subtract(1, 'days')
        .endOf('day')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      await downloadReport(start, end);
    } catch (err) {
      setErrorMessage(err.message);
    }
    setDownloadingYesterday(false);
  };

  const getReportDataThisWeek = async () => {
    setDownloadingThisWeek(true);

    setErrorMessage('');
    try {
      const start = moment()
        .subtract(12, 'hours')
        .startOf('isoWeek')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      const end = moment()
        .subtract(12, 'hours')
        .endOf('isoWeek')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      await downloadReport(start, end);
    } catch (err) {
      setErrorMessage(err.message);
    }
    setDownloadingThisWeek(false);
  };

  const getReportDataLastWeek = async () => {
    setDownloadingLastWeek(true);

    setErrorMessage('');
    try {
      const start = moment()
        .subtract(12, 'hours')
        .subtract(1, 'week')
        .startOf('isoWeek')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      const end = moment()
        .subtract(12, 'hours')
        .subtract(1, 'week')
        .endOf('isoWeek')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      await downloadReport(start, end);
    } catch (err) {
      setErrorMessage(err.message);
    }
    setDownloadingLastWeek(false);
  };

  const fetchCashs = async () => {
    const data = await getCashs();
    setCashData(data);
    const formatedData = formatData(data);
    setCashList(formatedData);
  };

  useEffect(() => {
    let mounted = true;

    const fetchAgents = () => {
      getAgents().then((data: any) => {
        if (mounted) {
          setAgents(data);
        }
      });
    };

    fetchAgents();

    const fetchConfigs = () => {
      getConfigs().then((data: any) => {
        if (mounted) {
          setConfigs(data);
          data.map((config: any) => {
            if (config.name === 'withdrawFee') {
              setWithdrawFee(Number(config.value));
            }
          });
        }
      });
    };

    fetchConfigs();
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    fetchCashs();
  }, [withdrawFee]);

  useEffect(() => {
    const newCashData: any = [];
    cashData.map((cash: any) => {
      const createdAtTime = new Date(cash.createdAt).getTime();
      const startDateTime = new Date(startDate).getTime();
      const endDateTime = new Date(endDate).getTime();
      if (startDateTime <= createdAtTime && createdAtTime <= endDateTime) {
        newCashData.push(cash);
      }
    });
    const formatedData = formatData(newCashData);
    setCashList(formatedData);
  }, [startDate, endDate]);

  const onUpdateCashs = async () => {
    setDownloading(true);
    setErrorMessage('');

    try {
      if (!startDate || !endDate) {
        setErrorMessage('请选择正确时间段');
      } else {
        const data = await updateCashs();
        fetchCashs();
      }
    } catch (err) {
      setErrorMessage(err.message);
    }
    setDownloading(false);
  };

  const onForceUpdateCashs = async () => {
    setDownloading2(true);
    setErrorMessage('');

    try {
      if (!startDate || !endDate) {
        setErrorMessage('请选择正确时间段');
      } else {
        const postData = {
          startDate: startDate,
          endDate: endDate,
        };
        const data = await forceUpdateCashs(postData);
        fetchCashs();
      }
    } catch (err) {
      setErrorMessage(err.message);
    }

    setDownloading2(false);
  };

  const onGenerateExcel = () => {
    setInputAgentError(false);

    if (!startDate || !endDate) {
      setErrorMessage('请选择正确时间段');
    } else {
      const cgAc = inputAgent.current.value;
      const startDateTime = new Date(startDate).getTime();
      const endDateTime = new Date(endDate).getTime();

      const visiableCashList = cashList.filter((x: any) =>
        visibleRows.includes(x.id)
      );
      if (!cgAc) {
        const data: any = [];
        visiableCashList
          .map((cash: any) => {
            const createdAtTime = new Date(cash.createdAt).getTime();
            if (
              createdAtTime >= startDateTime &&
              createdAtTime <= endDateTime
            ) {
              data.push(cash);
            }
          })
          .filter((cash: any) => cash);

        generateExcelFile({ configs, data });
      } else {
        const selected: any = agents.find(
          (agent: any) => agent.cgAccount === cgAc
        );
        if (selected) {
          const data: any = [];
          visiableCashList
            .map((cash: any) => {
              const createdAtTime = new Date(cash.createdAt).getTime();
              if (
                selected.cgAccount === cash.cgAccount &&
                createdAtTime >= startDateTime &&
                createdAtTime <= endDateTime
              ) {
                data.push(cash);
              }
            })
            .filter((cash: any) => cash);
          generateExcelFile({ configs, data });
        } else {
          setInputAgentError(true);
        }
      }
    }
  };

  const updateCashList = async (cgAccount: string) => {
    try {
      const data = await searchCashs({ cgAccount });

      if (data.length > 0) {
        setCashData(data);
        const formatedData = formatData(data);
        setCashList(formatedData);
      } else {
        setCashData([]);
        setCashList([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeAgent = (event: any, newValue: any) => {
    setAgcode(newValue.agcode);
    updateCashList(newValue.cgAccount);
  };

  return (
    <>
      <Page className={classes.root} title="现金表">
        <Container maxWidth={false}>
          <Header />
          <Card className={classes.card}>
            <Grid container spacing={2} mt={0}>
              <Autocomplete
                // value={agcode}
                freeSolo
                options={agents}
                getOptionLabel={(option: any) =>
                  option.cgAccount ? option.cgAccount : ''
                }
                disableClearable
                classes={{
                  root: classes.autoCompleteRoot,
                  inputRoot: classes.inputRoot,
                }}
                autoHighlight={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={'outlined'}
                    placeholder={'输入cg代码'}
                    inputRef={inputAgent}
                    InputProps={{ ...params.InputProps, type: 'search' }}
                    style={{ width: 150 }}
                  />
                )}
                onChange={handleChangeAgent}
              />
              {inputAgentError ? (
                <div className={classes.inputError}>请输入正确的cg户口</div>
              ) : null}
              <Box style={{ marginLeft: 20, marginTop: 20 }}>
                <DateTimePicker
                  label="开始日期"
                  value={startDate}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  onChange={(newValue: any) => {
                    setStartDate(newValue);
                  }}
                />
              </Box>
              <Box style={{ marginLeft: 20, marginTop: 20 }}>
                <DateTimePicker
                  label="结束日期"
                  value={endDate}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  onChange={(newValue: any) => {
                    setEndDate(newValue);
                  }}
                />
              </Box>
              <Button
                color="primary"
                className={classes.editButton}
                variant="contained"
                onClick={onGenerateExcel}
                style={{ marginLeft: 20, marginTop: 20 }}
              >
                导出指定时间段报表
              </Button>

              {!downloading ? (
                <Button
                  color="error"
                  className={classes.editButton}
                  variant="contained"
                  onClick={onUpdateCashs}
                  style={{ marginLeft: 20, marginTop: 20 }}
                >
                  刷新
                </Button>
              ) : (
                <Downloading />
              )}
              {!downloading2 ? (
                <Button
                  color="primary"
                  className={classes.editButton}
                  variant="contained"
                  onClick={onForceUpdateCashs}
                  style={{ marginLeft: 20, marginTop: 20 }}
                >
                  重新导入缺失记录
                </Button>
              ) : (
                <Downloading />
              )}
            </Grid>
            <Grid container mt={2}>
              <Grid item xs={12} md={1.5} mt={2}>
                {!downloadingToday ? (
                  <Button
                    color="secondary"
                    className={classes.editButton}
                    variant="contained"
                    onClick={getReportDataToday}
                    style={{ marginLeft: 20 }}
                  >
                    今日报表
                  </Button>
                ) : (
                  <Downloading />
                )}
              </Grid>
              <Grid item xs={12} md={1.5} mt={2}>
                {!downloadingYesterday ? (
                  <Button
                    color="secondary"
                    className={classes.editButton}
                    variant="contained"
                    onClick={getReportDataYesterday}
                    style={{ marginLeft: 20 }}
                  >
                    昨日报表
                  </Button>
                ) : (
                  <Downloading />
                )}
              </Grid>
              <Grid item xs={12} md={1.5} mt={2}>
                {!downloadingThisWeek ? (
                  <Button
                    color="secondary"
                    className={classes.editButton}
                    variant="contained"
                    onClick={getReportDataThisWeek}
                    style={{ marginLeft: 20 }}
                  >
                    本周报表
                  </Button>
                ) : (
                  <Downloading />
                )}
              </Grid>
              <Grid item xs={12} md={1.5} mt={2}>
                {!downloadingLastWeek ? (
                  <Button
                    color="secondary"
                    className={classes.editButton}
                    variant="contained"
                    onClick={getReportDataLastWeek}
                    style={{ marginLeft: 20 }}
                  >
                    上周报表
                  </Button>
                ) : (
                  <Downloading />
                )}
              </Grid>
            </Grid>
            <span style={{ color: 'red' }}>
              {errorMessage ? errorMessage : ''}
            </span>
          </Card>
          <Card className={classes.card}>
            {cashList?.length > 0 && (
              <CashDetail
                cashList={cashList}
                setVisibleRows={setVisibleRows}
                withdrawFee={withdrawFee}
              />
            )}
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default Cash;
