import React, { useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { darken, lighten } from '@mui/material/styles';
import { Box } from '@mui/material';

const columns: GridColDef[] = [
  {
    headerName: '起始时间',
    field: 'startDate',
    width: 160,
  },
  {
    headerName: '截止时间',
    field: 'endDate',
    width: 160,
  },
  {
    headerName: 'AG结算时间',
    field: 'settledAt',
    width: 160,
  },
  {
    headerName: '币种',
    field: 'ccy',
    width: 60,
  },
  {
    headerName: '种类',
    field: 'mc',
    align: 'center',
    width: 60,
  },
  {
    headerName: 'CG户口',
    field: 'cgAccount',
  },
  {
    headerName: '投注输赢',
    field: 'winlose',
    align: 'right',
    type: 'number',
  },
  {
    headerName: '转码',
    field: 'wash',
    align: 'right',
    type: 'number',
  },
  {
    headerName: '小费',
    field: 'tip',
    align: 'right',
    type: 'number',
  },
  {
    headerName: '代理占成',
    field: 'agentPercent',
    align: 'center',
  },
  // {
  //   headerName: '公司占成',
  //   field: 'company1Percent',
  //   align: 'center',
  // },
  // {
  //   headerName: 'SLE占成',
  //   field: 'company2Percent',
  //   align: 'center',
  // },
  {
    headerName: '代理佣金比',
    field: 'agentCommission',
    align: 'center',
  },
  {
    headerName: '佣金',
    field: 'agentCommissionAmount',
    align: 'right',
    // type: 'number',
  },
  {
    headerName: '股东佣金差比',
    field: 'commissionDiff',
    align: 'center',
    width: 120,
  },
  {
    headerName: '股东佣金差',
    field: 'commissionDiffAmount',
    align: 'right',
    // type: 'number',
  },
  {
    headerName: '代理应收付',
    field: 'actualSettle',
    align: 'right',
    // type: 'number',
  },
  // {
  //   headerName: '公司输赢',
  //   field: 'company1Amount',
  //   align: 'right',
  //   type: 'number',
  // },
  // {
  //   headerName: 'SLE输赢',
  //   field: 'company2Amount',
  //   align: 'right',
  //   type: 'number',
  // },
  {
    headerName: '结算方式',
    field: 'settleType',
    align: 'center',
  },
  {
    headerName: '上分数',
    field: 'upAmount',
    align: 'right',
    // type: 'number',
  },
  {
    headerName: '现金转出数',
    field: 'transferAmount',
    align: 'right',
    // type: 'number',
  },
  {
    headerName: '出入款户口',
    field: 'transferAccount',
    type: 'singleSelect',
    align: 'center',
  },
  {
    headerName: '备注',
    field: 'comment',
    width: 180,
  },
];

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const SettleDetail: React.FC<any> = (props: any) => {
  const [pageSize, setPageSize] = useState(10);
  const { onEditSettlement } = props;

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .super-app-theme--unsettled': {
          bgcolor: (theme: any) =>
            getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
          '&:hover': {
            bgcolor: (theme: any) =>
              getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode
              ),
          },
        },
      }}
    >
      <DataGrid
        rows={props.data}
        columns={columns}
        rowHeight={36}
        rowsPerPageOptions={[10, 25, 50, 100]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
        onRowClick={(params, event) => {
          onEditSettlement(params.id);
        }}
        getRowClassName={(params: any) =>
          `super-app-theme--${
            params.row.settleType === '' ? 'unsettled' : 'settled'
          }`
        }
        localeText={{
          noRowsLabel: '没有数据。',
          noResultsOverlayLabel: '未找到数据。',
          errorOverlayDefaultLabel: '发生错误。',

          // Density selector toolbar button text
          toolbarDensity: '表格密度',
          toolbarDensityLabel: '表格密度',
          toolbarDensityCompact: '紧密',
          toolbarDensityStandard: '标准',
          toolbarDensityComfortable: '稀疏',

          // Columns selector toolbar button text
          toolbarColumns: '列',
          toolbarColumnsLabel: '选择列',

          // Filters toolbar button text
          toolbarFilters: '筛选器',
          toolbarFiltersLabel: '显示筛选器',
          toolbarFiltersTooltipHide: '隐藏筛选器',
          toolbarFiltersTooltipShow: '显示筛选器',
          toolbarFiltersTooltipActive: (count: number) => `${count} 个筛选器`,

          // Export selector toolbar button text
          toolbarExport: '导出',
          toolbarExportLabel: '导出',
          toolbarExportCSV: '导出至CSV',
          // toolbarExportPrint: 'Print',

          // Columns panel text
          columnsPanelTextFieldLabel: '搜索列',
          columnsPanelTextFieldPlaceholder: '列名',
          columnsPanelDragIconLabel: '重排序列',
          columnsPanelShowAllButton: '显示所有',
          columnsPanelHideAllButton: '隐藏所有',

          // Filter panel text
          filterPanelAddFilter: '添加筛选器',
          filterPanelDeleteIconLabel: '删除',
          filterPanelOperators: '操作器',
          filterPanelOperatorAnd: '与',
          filterPanelOperatorOr: '或',
          filterPanelColumns: '列',
          filterPanelInputLabel: '值',
          filterPanelInputPlaceholder: '筛选值',

          // Filter operators text
          filterOperatorContains: '包含',
          filterOperatorEquals: '等于',
          filterOperatorStartsWith: '开始于',
          filterOperatorEndsWith: '结束于',
          filterOperatorIs: '是',
          filterOperatorNot: '不是',
          filterOperatorAfter: '在后面',
          filterOperatorOnOrAfter: '正在后面',
          filterOperatorBefore: '在前面',
          filterOperatorOnOrBefore: '正在前面',
          filterOperatorIsEmpty: '为空',
          filterOperatorIsNotEmpty: '不为空',
          // filterOperatorIsAnyOf: 'is any of',

          // Filter values text
          filterValueAny: '任何',
          filterValueTrue: '真',
          filterValueFalse: '假',

          // Column menu text
          columnMenuLabel: '菜单',
          columnMenuShowColumns: '显示',
          columnMenuFilter: '筛选器',
          columnMenuHideColumn: '隐藏',
          columnMenuUnsort: '恢复默认',
          columnMenuSortAsc: '升序',
          columnMenuSortDesc: '降序',

          // Column header text
          columnHeaderFiltersTooltipActive: (count: number) =>
            count !== 1 ? `${count} 个筛选器` : `${count} 个筛选器`,
          columnHeaderFiltersLabel: '显示筛选器',
          columnHeaderSortIconLabel: '排序',

          // Rows selected footer text
          footerRowSelected: (count: number) =>
            `共选中了${count.toLocaleString()}行`,

          // Total rows footer text
          footerTotalRows: '所有行:',

          // Total visible rows footer text
          footerTotalVisibleRows: (visibleCount, totalCount) =>
            `${visibleCount.toLocaleString()} / ${totalCount.toLocaleString()}`,

          // Checkbox selection text
          checkboxSelectionHeaderName: '多选框',
          // checkboxSelectionSelectAllRows: 'Select all rows',
          // checkboxSelectionUnselectAllRows: 'Unselect all rows',
          // checkboxSelectionSelectRow: 'Select row',
          // checkboxSelectionUnselectRow: 'Unselect row',

          // Boolean cell text
          booleanCellTrueLabel: '真',
          booleanCellFalseLabel: '假',

          // Actions cell more text
          // actionsCellMore: 'more',

          // Column pinning text
          // pinToLeft: 'Pin to left',
          // pinToRight: 'Pin to right',
          // unpin: 'Unpin',

          // Tree Data
          // treeDataGroupingHeaderName: 'Group',
          // treeDataExpand: 'see children',
          // treeDataCollapse: 'hide children',

          // Grouping columns
          // groupingColumnHeaderName: 'Group',
          // groupColumn: name => `Group by ${name}`,
          // unGroupColumn: name => `Stop grouping by ${name}`,

          // Master/detail
          // expandDetailPanel: 'Expand',
          // collapseDetailPanel: 'Collapse',
        }}
      />
    </Box>
  );
};

export default SettleDetail;
