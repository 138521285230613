import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Container,
  Box,
  Button,
  TextField,
  CircularProgress,
  Typography,
  Card,
  Dialog,
  Autocomplete,
  Grid,
} from '@mui/material';
import DateTimePicker from '../../components/DateTimePicker';
import Page from '../../components/Page';
import Header from './Header';
import SettleRow from './SettleRow';
import agApi from '../../api';
import { useHistoryState } from '../../utils/useHistoryState';
import moment from 'moment-timezone';
import generateExcelFile from './generateExcelFile';
import SettleDetail from './SettleDetail';
import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  editButton: {
    textTransform: 'none',
    marginLeft: '50px',
  },
  circularProgress: {
    marginLeft: '20px',
  },
  card: {
    padding: 20,
    marginTop: 5,
  },
  autoCompleteRoot: {
    marginLeft: 20,
    marginTop: 20,
    padding: 0,
  },
  inputRoot: {
    border: '0px',
    borderRadius: '5px',
    borderColor: '#ffffff',
    backgroundColor: '#ffffff',
    marginRight: 20,
  },
  inputError: {
    color: '#cd2d2d',
    fontSize: '16px',
    margin: '22px 0 10px 5px',
    '&:before': {
      content: '',
      display: 'inline-block',
      width: '30px',
      height: '30px',
      backgroundimage: "url('../../asset/img/error.svg')",
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      marginRight: '15px',
      marginBottom: '-6px',
    },
  },
}));

const Downloading = () => {
  const classes = useStyles();
  return (
    <Box
      position="relative"
      display="inline-flex"
      className={classes.circularProgress}
      style={{ marginTop: 20, marginLeft: 20 }}
    >
      <CircularProgress />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          处理中
        </Typography>
      </Box>
    </Box>
  );
};

const CustomerManagementList: React.FC = () => {
  const classes = useStyles();
  const { mutateAsync: getSettlementData } = agApi('getSettlementData');
  const { mutateAsync: getAgents } = agApi('getAgents');
  const { mutateAsync: getAgentSettlements } = agApi('getAgentSettlements');
  const { mutateAsync: settleLastWeek } = agApi('settleLastWeek');
  const [agcode, setAgcode] = useState();
  const { mutateAsync: getLastSettlementByAgcode } = agApi(
    'getLastSettlementByAgcode',
    { agcode }
  );
  const [settlements, setSettlements] = useState<any>([]);
  const [formatedData, setFormatedData] = useState<any>([]);

  const inputAgent = useRef(null);

  const [startDate, setStartDate] = useHistoryState(
    'settleStartDate',
    // eslint-disable-next-line newline-per-chained-call
    moment()
      .subtract(1, 'days')
      .startOf('day')
      .add(12, 'hours')
      .format('YYYY-MM-DD HH:mm:ss')
  );

  const [endDate, setEndDate] = useHistoryState(
    'settleEndDate',
    moment().tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss')
  );

  const [downloading, setDownloading] = useState(false);
  const [downloading2, setDownloading2] = useState(false);
  const [agents, setAgents] = useState([{}]);
  const [inputAgentError, setInputAgentError] = useState<undefined | boolean>(
    undefined
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [settlement, setSettlement] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState('');

  const formatData = (data: any) => {
    const newData = data.map((row: any) => {
      const { settleData } = row;
      let settleType = '';
      switch (settleData.settleType) {
        case 'up':
          settleType = '上分';
          break;
        case 'down':
          settleType = '下分';
          break;
        case 'transfer':
          settleType = '现金转出';
          break;
        case 'upTransfer':
          settleType = '上分+现金转出';
          break;
        case 'zero':
          settleType = '0交收';
          break;
        default:
          settleType = '';
      }
      return {
        id: row.settlementId,
        startDate: moment(row.startDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(row.endDate).format('YYYY-MM-DD HH:mm:ss'),
        settledAt: row.settledAt
          ? moment(row.settledAt).format('YYYY-MM-DD HH:mm:ss')
          : '',
        ccy: settleData.ccy,
        mc: settleData.mc,
        cgAccount: settleData.cgAccount,
        winlose: settleData.winlose,
        wash: settleData.wash,
        tip: settleData.tip,
        agentPercent: settleData.agentPercent + '%',
        company1Percent: settleData.company1Percent + '%',
        company2Percent: settleData.company2Percent + '%',
        agentCommission: settleData.agentCommission + '%',
        agentCommissionAmount: settleData.agentCommissionAmount,
        commissionDiff: settleData.commissionDiff + '%',
        commissionDiffAmount: settleData.commissionDiffAmount,
        actualSettle: settleData.actualSettle,
        company1Amount: settleData.company1Amount,
        company2Amount: settleData.company2Amount,
        settleType: settleType,
        upAmount: settleData.upAmount,
        transferAmount: settleData.transferAmount,
        transferAccount: settleData.transferAccount,
        comment: settleData.comment,
      };
    });
    setFormatedData(newData);
  };

  useEffect(() => {
    let mounted = true;

    const fetchAgents = () => {
      getAgents().then((data: any) => {
        if (mounted) {
          setAgents(data);
        }
      });
    };

    fetchAgents();

    const fetchSettlements = () => {
      getAgentSettlements().then((data: any) => {
        if (mounted) {
          setSettlements(data);
          formatData(data);
        }
      });
    };

    fetchSettlements();

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    const fetchSettlement = async () => {
      try {
        const data = await getLastSettlementByAgcode();
        const newStartDate = moment(data.endDate)
          .add(1, 'second')
          .format('YYYY-MM-DD HH:mm:ss');
        const newEndDate = moment()
          .tz('Asia/Shanghai')
          .format('YYYY-MM-DD HH:mm:ss');
        setStartDate(newStartDate);
        setEndDate(newEndDate);
      } catch (err) {
        // No found
      }
    };

    fetchSettlement();
  }, [agcode]);

  const onGetSettlementData = async () => {
    setDownloading(true);
    setInputAgentError(false);
    setErrorMessage('');

    if (!startDate || !endDate) {
      setDownloading(false);
    } else {
      const cgAccount = inputAgent.current.value;
      const selected: any = agents.find(
        (agent: any) => agent.cgAccount === cgAccount
      );
      if (selected) {
        const postData = {
          agentId: selected.agentId,
          agcode: selected.agcode,
          startDate: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
          endDate: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
        };

        let data;
        try {
          data = await getSettlementData(postData);
          setSettlement(data);
          setDownloading(false);
          setOpenDialog(true);
        } catch (err) {
          setErrorMessage(err.message);
          setDownloading(false);
        }
      } else {
        setDownloading(false);
        setInputAgentError(true);
      }
    }
  };

  const updateSettlements = async (agentId: number) => {
    try {
      const data = await getAgentSettlements({ agentId });

      if (data.length > 0) {
        const newSettlements: any = [];
        data.map((s: any) => {
          const replace = data.find(
            (d: any) => d.settlementId === s.settlementId
          );
          if (replace) {
            newSettlements.push(replace);
          } else {
            newSettlements.push(s);
          }
        });
        setSettlements(newSettlements);
        formatData(newSettlements);
      } else {
        setSettlements([]);
        formatData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeAgent = (event: any, newValue: any) => {
    setAgcode(newValue.agcode);
    updateSettlements(newValue?.agentId);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleEditSettlement = (id: number) => {
    console.log(settlements);
    const editData = settlements.find((s: any) => s.settlementId === id);
    setSettlement(editData);
    setOpenDialog(true);
  };

  const onGenerateExcel = () => {
    setInputAgentError(false);

    if (startDate && endDate) {
      const startDateTime = new Date(startDate).getTime();
      const endDateTime = new Date(endDate).getTime();

      const data: any = [];
      settlements.map((settle: any) => {
        const settleStartDateTime = new Date(
          settle.settleData.startDate
        ).getTime();
        const settleEndDateTime = new Date(settle.settleData.endDate).getTime();
        if (
          settleStartDateTime >= startDateTime &&
          settleEndDateTime <= endDateTime
        ) {
          data.push(settle);
        }
      });
      generateExcelFile({ data });
    }
  };

  const handleSettleLastWeek = async () => {
    setDownloading2(true);
    setErrorMessage('');
    try {
      const start = moment()
        .subtract(12, 'hours')
        .subtract(1, 'week')
        .startOf('isoWeek')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      const end = moment()
        .subtract(12, 'hours')
        .subtract(1, 'week')
        .endOf('isoWeek')
        .add(12, 'hours')
        .format('YYYY-MM-DD HH:mm:ss');
      const postData = {
        startDate: start,
        endDate: end,
      };
      const data = await settleLastWeek(postData);
      setSettlements(data);
      formatData(data);
    } catch (err) {
      setErrorMessage(err.message);
    }
    setDownloading2(false);
  };

  return (
    <>
      <Page className={classes.root} title="结算">
        <Container maxWidth={false}>
          <Header />
          <Card className={classes.card}>
            <Grid container spacing={2} mt={0}>
              <Autocomplete
                // value={agcode}
                freeSolo
                options={agents}
                getOptionLabel={(option: any) =>
                  option.cgAccount ? option.cgAccount : ''
                }
                disableClearable
                classes={{
                  root: classes.autoCompleteRoot,
                  inputRoot: classes.inputRoot,
                }}
                autoHighlight={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={'outlined'}
                    placeholder={'输入cg代码'}
                    inputRef={inputAgent}
                    InputProps={{ ...params.InputProps, type: 'search' }}
                    style={{ width: 150 }}
                  />
                )}
                onChange={handleChangeAgent}
                onInputChange={(event, newInputValue, reason) => {
                  if (reason === 'input' && newInputValue === '') {
                    getAgentSettlements().then((data: any) => {
                      setSettlements(data);
                      formatData(data);
                    });
                  }
                }}
              />
              {inputAgentError ? (
                <div className={classes.inputError}>请输入正确的cg户口</div>
              ) : null}

              <Box style={{ marginLeft: 20, marginTop: 20 }}>
                <DateTimePicker
                  label="开始日期"
                  value={startDate}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  onChange={(newValue: any) => {
                    setStartDate(newValue);
                  }}
                />
              </Box>
              <Box style={{ marginLeft: 20, marginTop: 20 }}>
                <DateTimePicker
                  label="结束日期"
                  value={endDate}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  onChange={(newValue: any) => {
                    setEndDate(newValue);
                  }}
                />
              </Box>

              {!downloading ? (
                <Button
                  color="primary"
                  className={classes.editButton}
                  variant="contained"
                  onClick={onGetSettlementData}
                  style={{ marginLeft: 20, marginTop: 20 }}
                >
                  开始结算
                </Button>
              ) : (
                <Downloading />
              )}

              <Button
                color="secondary"
                className={classes.editButton}
                variant="contained"
                onClick={onGenerateExcel}
                style={{ marginLeft: 20, marginTop: 20 }}
              >
                导出指定时间段报表
              </Button>
              {!downloading2 ? (
                <Button
                  color="success"
                  className={classes.editButton}
                  variant="contained"
                  onClick={handleSettleLastWeek}
                  style={{ marginLeft: 20, marginTop: 20 }}
                >
                  上周所有未交付结算
                </Button>
              ) : (
                <Downloading />
              )}
            </Grid>
            <span style={{ color: 'red' }}>
              {errorMessage ? errorMessage : ''}
            </span>
          </Card>
          <Card className={classes.card}>
            {settlements?.length > 0 && (
              <SettleDetail
                data={formatedData}
                onEditSettlement={handleEditSettlement}
              />
            )}
          </Card>
        </Container>
      </Page>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        PaperComponent={PaperComponent}
        fullWidth
        maxWidth="lg"
        aria-labelledby="draggable-dialog-title"
      >
        {settlement && (
          <SettleRow
            settlement={settlement}
            handleDialogClose={handleDialogClose}
            updateSettlements={updateSettlements}
          />
        )}
      </Dialog>
    </>
  );
};

export default CustomerManagementList;
