const ExcelJS = require('exceljs');

const generateExcelFile = async (params: any) => {
  const {
    data: { data: allData, monthlySum },
  } = params;

  const workbook = new ExcelJS.Workbook();
  if (allData) {
    const data = allData;
    const worksheet = workbook.addWorksheet('总表');
    worksheet.columns = [
      { header: '币种', key: 'ccy', width: 13 },
      { header: '类型', key: 'mc', width: 13 },
      { header: '户名', key: 'agentName', width: 13 },
      { header: '代理户口', key: 'cqAccount', width: 13 },
      { header: 'CG户口', key: 'cgAccount', width: 13 },
      {
        header: '投注输赢',
        key: 'winlose',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '转码',
        key: 'wash',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '小费',
        key: 'tip',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '代理占成',
        key: 'agentPercent',
        width: 13,
        style: { numFmt: '#0.##%' },
      },
      {
        header: '公司占成',
        key: 'company1Percent',
        width: 13,
        style: { numFmt: '#0.##%' },
      },
      {
        header: 'SLE占成',
        key: 'company2Percent',
        width: 13,
        style: { numFmt: '#0.##%' },
      },
      {
        header: '代理佣金比',
        key: 'agentCommission',
        width: 13,
        style: { numFmt: '#0.##%' },
      },
      {
        header: '佣金',
        key: 'agentCommissionAmount',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '股东佣金差比',
        key: 'commissionDiff',
        width: 13,
        style: { numFmt: '#0.##%' },
      },
      {
        header: '股东佣金差',
        key: 'commissionDiffAmount',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '代理应收付',
        key: 'actualSettle',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '公司输赢',
        key: 'company1Amount',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: 'SLE输赢',
        key: 'company2Amount',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      { header: '', key: 'settleStatus', width: 14 },
      { header: '', key: 'settlePeriod', width: 40 },
    ];
    worksheet.getRow(1).height = 30;
    [
      'A1',
      'B1',
      'C1',
      'D1',
      'E1',
      'F1',
      'G1',
      'H1',
      'I1',
      'J1',
      'K1',
      'L1',
      'M1',
      'N1',
      'O1',
      'P1',
      'Q1',
      'R1',
    ].map((key) => {
      worksheet.getCell(key).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' },
        bgColor: { argb: 'D9D9D9' },
      };
      worksheet.getCell(key).border = {
        top: { style: 'medium' },
        left: { style: 'medium' },
        bottom: { style: 'medium' },
        right: { style: 'medium' },
      };
    });
    let startRowNo = 2;
    let emptyRowCount = 0;
    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const rowNo = i + 2 + emptyRowCount;

      let convertedRow = {};

      convertedRow = {
        ccy: row['ccy'],
        mc: row['mc'] === 'P' ? '配码上分' : row['mc'],
        agentName: row['agentName'],
        cqAccount: row['cqAccount'],
        cgAccount: row['cgAccount'],
        winlose: Number((row['winlose'] / 10000).toFixed(4)),
        wash: Number((row['wash'] / 10000).toFixed(4)),
        tip: Number((row['tip'] / 10000).toFixed(4)),
        agentPercent: Number((row['agentPercent'] / 100).toFixed(4)),
        company1Percent: Number((row['company1Percent'] / 100).toFixed(4)),
        company2Percent: Number((row['company2Percent'] / 100).toFixed(4)),
        agentCommission: Number((row['agentCommission'] / 100).toFixed(4)),
        agentCommissionAmount: { formula: `ROUND(G${rowNo}*L${rowNo}, 4)` },
        commissionDiff: Number((row['commissionDiff'] / 100).toFixed(4)),
        commissionDiffAmount: { formula: `ROUND(G${rowNo}*N${rowNo}, 4)` },
        actualSettle: {
          formula: `ROUND(IF(B${rowNo}="C",(F${rowNo}*I${rowNo}+M${rowNo}+O${rowNo}),IF(B${rowNo}="M",-(F${rowNo}*(100%-I${rowNo})-M${rowNo}-O${rowNo}+H${rowNo}),M${rowNo})), 4)`,
        },
        company1Amount: {
          formula: `ROUND((F${rowNo}*J${rowNo}-M${rowNo}-O${rowNo}), 4)`,
        },
        company2Amount: {
          formula: `ROUND((F${rowNo}*K${rowNo}+H${rowNo}), 4)`,
        },
        settleStatus:
          row['agentHasSettled'] === 1
            ? `已结算-${row['settleType'] === '' ? '未交收' : '已交收'}`
            : row['agentHasSettled'] === 0
            ? '未结算'
            : '',
        settlePeriod:
          row['agentHasSettled'] === 1 || row['agentHasSettled'] === 0
            ? `${row['startDate']} - ${row['endDate']}`
            : '',
      };

      const addedRow = worksheet.addRow(convertedRow);
      let fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E2EFDA' },
        bgColor: { argb: 'E2EFDA' },
      };
      let border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      if (row['ccy'] === 'PHP') {
        fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'f4f7c8' },
          bgColor: { argb: 'f4f7c8' },
        };
        border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      if (row['ccy'] === 'RMB') {
        fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'c8e7f7' },
          bgColor: { argb: 'c8e7f7' },
        };
        border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      const arr = Array.from({ length: 18 }, (_, j) => j + 1);
      arr.map((x) => (worksheet.getCell(worksheet.rowCount, x).fill = fill));
      arr.map(
        (x) => (worksheet.getCell(worksheet.rowCount, x).border = border)
      );
      if (i < data.length - 1 && data[i]['ccy'] !== data[i + 1]['ccy']) {
        const suSumRow = {
          ccy: '',
          mc: '合计：',
          agentName: '',
          cqAccount: '',
          cgAccount: '',
          winlose: { formula: `SUM(F${startRowNo}:F${rowNo})` },
          wash: { formula: `SUM(G${startRowNo}:G${rowNo})` },
          tip: { formula: `SUM(H${startRowNo}:H${rowNo})` },
          agentPercent: '',
          company1Percent: '',
          company2Percent: '',
          agentCommission: '',
          agentCommissionAmount: { formula: `SUM(M${startRowNo}:M${rowNo})` },
          commissionDiff: '',
          commissionDiffAmount: { formula: `SUM(O${startRowNo}:O${rowNo})` },
          actualSettle: { formula: `SUM(P${startRowNo}:P${rowNo})` },
          company1Amount: { formula: `SUM(Q${startRowNo}:Q${rowNo})` },
          company2Amount: { formula: `SUM(R${startRowNo}:R${rowNo})` },
          settleStatus: '',
        };
        worksheet.addRow(suSumRow);
        emptyRowCount += 1;
        startRowNo = rowNo + 2;
      }
    }

    // summary row
    const count = data.length;
    const sumRowNo = count + 1 + emptyRowCount;
    const sumRow = {
      ccy: '',
      mc: '合计：',
      agentName: '',
      cqAccount: '',
      cgAccount: '',
      winlose: { formula: `SUM(F${startRowNo}:F${sumRowNo})` },
      wash: { formula: `SUM(G${startRowNo}:G${sumRowNo})` },
      tip: { formula: `SUM(H${startRowNo}:H${sumRowNo})` },
      agentPercent: '',
      company1Percent: '',
      company2Percent: '',
      agentCommission: '',
      agentCommissionAmount: { formula: `SUM(M${startRowNo}:M${sumRowNo})` },
      commissionDiff: '',
      commissionDiffAmount: { formula: `SUM(O${startRowNo}:O${sumRowNo})` },
      actualSettle: { formula: `SUM(P${startRowNo}:P${sumRowNo})` },
      company1Amount: { formula: `SUM(Q${startRowNo}:Q${sumRowNo})` },
      company2Amount: { formula: `SUM(R${startRowNo}:R${sumRowNo})` },
      settleStatus: '',
    };
    const addedSum = worksheet.addRow(sumRow);

    if (params.includeMonthlySum) {
      // 报数
      let winloseSumForA = 0;
      let washSumForA = 0;
      let winloseSumForB = 0;
      let washSumForB = 0;
      allData.map((row: any) => {
        if (row['agentPercent'] === 0) {
          // A数
          winloseSumForA += Number(row['winlose']);
          washSumForA += Number(row['wash']);
        } else {
          // B数
          winloseSumForB += Number(row['winlose']);
          washSumForB += Number(row['wash']);
        }
      });
      const worksheet = workbook.addWorksheet('报数');
      worksheet.columns = [
        { header: '', key: 'name1', width: 20 },
        { header: '', key: 'value1', width: 13 },
        { header: '', key: 'name2', width: 20 },
        { header: '', key: 'value2', width: 13 },
      ];
      worksheet.addRow({ name1: '<人民币>' });
      worksheet.addRow({ name1: 'A数:' });
      worksheet.addRow({
        name1: `日上: `,
        value1: Number(Number(winloseSumForA / 10000).toFixed(4)),
        name2: `全月下: `,
        value2: Number(Number(monthlySum.winloseSumForA / 10000).toFixed(4)),
      });
      worksheet.addRow({
        name1: `日轉碼: `,
        value1: Number(Number(washSumForA / 10000).toFixed(4)),
        name2: `全月轉碼: `,
        value2: Number(Number(monthlySum.washSumForA / 10000).toFixed(4)),
      });
      worksheet.addRow({ name1: 'B数:' });
      worksheet.addRow({
        name1: `日上:`,
        value1: Number(Number(winloseSumForB / 10000).toFixed(4)),
        name2: `全月上: `,
        value2: Number(Number(monthlySum.winloseSumForB / 10000).toFixed(4)),
      });
      worksheet.addRow({
        name1: `日轉碼: `,
        value1: Number(Number(washSumForB / 10000).toFixed(4)),
        name2: `全月轉碼: `,
        value2: Number(Number(monthlySum.washSumForB / 10000).toFixed(4)),
      });

      worksheet.addRow({ name1: '' });
      worksheet.addRow({ name1: '<總結>' });
      worksheet.addRow({
        name1: '当日A+B上: ',
        value1: Number(
          Number(
            (Number(winloseSumForA) + Number(winloseSumForB)) / 10000
          ).toFixed(4)
        ),
      });
      worksheet.addRow({
        name1: '全月A+B总上: ',
        value1: Number(
          Number(
            (Number(monthlySum.winloseSumForA) +
              Number(monthlySum.winloseSumForB)) /
              10000
          ).toFixed(4)
        ),
      });
      worksheet.addRow({
        name1: '当日A+B转码: ',
        value1: Number(
          Number((Number(washSumForA) + Number(washSumForB)) / 10000).toFixed(4)
        ),
      });
      worksheet.addRow({
        name1: '全月A+B总转码: ',
        value1: Number(
          Number(
            (Number(monthlySum.washSumForA) + Number(monthlySum.washSumForB)) /
              10000
          ).toFixed(4)
        ),
      });
      worksheet.addRow({
        name1: '公司全月占成總下: ',
        value1: { formula: `=ROUND(总表!Q${sumRowNo + 1}/B16,4)` },
      });
      worksheet.addRow({ name1: '汇率: ', value1: 0.955 });
    }
  }

  // 代理分表
  const agentData = new Object();
  allData.map((row: any) => {
    if (Object.keys(agentData).indexOf(row.cgAccount) === -1) {
      Object.assign(agentData, { [row.cgAccount]: [row] });
    } else {
      agentData[row.cgAccount].push(row);
    }
  });
  console.log(agentData);

  Object.keys(agentData).map((agent) => {
    const data = agentData[agent];
    const worksheet = workbook.addWorksheet(agent);
    worksheet.columns = [
      { header: '币种', key: 'ccy', width: 13 },
      { header: '类型', key: 'mc', width: 13 },
      { header: '户名', key: 'agentName', width: 13 },
      { header: '代理户口', key: 'cqAccount', width: 13 },
      { header: 'CG户口', key: 'cgAccount', width: 13 },
      {
        header: '投注输赢',
        key: 'winlose',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '转码',
        key: 'wash',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '小费',
        key: 'tip',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '代理占成',
        key: 'agentPercent',
        width: 13,
        style: { numFmt: '#0.##%' },
      },
      {
        header: '代理佣金比',
        key: 'agentCommission',
        width: 13,
        style: { numFmt: '#0.##%' },
      },
      {
        header: '佣金',
        key: 'agentCommissionAmount',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '股东佣金差比',
        key: 'commissionDiff',
        width: 13,
        style: { numFmt: '#0.##%' },
      },
      {
        header: '股东佣金差',
        key: 'commissionDiffAmount',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '代理应收付',
        key: 'actualSettle',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      { header: '', key: 'settleStatus', width: 14 },
      { header: '', key: 'settlePeriod', width: 40 },
    ];
    worksheet.getRow(1).height = 30;
    [
      'A1',
      'B1',
      'C1',
      'D1',
      'E1',
      'F1',
      'G1',
      'H1',
      'I1',
      'J1',
      'K1',
      'L1',
      'M1',
      'N1',
    ].map((key) => {
      worksheet.getCell(key).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' },
        bgColor: { argb: 'D9D9D9' },
      };
      worksheet.getCell(key).border = {
        top: { style: 'medium' },
        left: { style: 'medium' },
        bottom: { style: 'medium' },
        right: { style: 'medium' },
      };
    });
    let startRowNo = 2;
    let emptyRowCount = 0;
    let settledRowCount = 0;
    for (let i = 0; i < data.length; i++) {
      const row = data[i];
      const rowNo = i + 2 + emptyRowCount;
      if (row['settleType'] !== '') {
        settledRowCount += 1;
      }

      let convertedRow = {};

      convertedRow = {
        ccy: row['ccy'],
        mc: row['mc'] === 'P' ? '配码上分' : row['mc'],
        agentName: row['agentName'],
        cqAccount: row['cqAccount'],
        cgAccount: row['cgAccount'],
        winlose: Number((row['winlose'] / 10000).toFixed(4)),
        wash: Number((row['wash'] / 10000).toFixed(4)),
        tip: Number((row['tip'] / 10000).toFixed(4)),
        agentPercent: Number((row['agentPercent'] / 100).toFixed(4)),
        agentCommission: Number((row['agentCommission'] / 100).toFixed(4)),
        agentCommissionAmount: { formula: `ROUND(G${rowNo}*J${rowNo}, 4)` },
        commissionDiff: Number((row['commissionDiff'] / 100).toFixed(4)),
        commissionDiffAmount: { formula: `ROUND(G${rowNo}*L${rowNo}, 4)` },
        actualSettle: {
          formula: `ROUND(IF(B${rowNo}="C",(F${rowNo}*I${rowNo}+K${rowNo}+M${rowNo}),IF(B${rowNo}="M",-(F${rowNo}*(100%-I${rowNo})-K${rowNo}-M${rowNo}+H${rowNo}),K${rowNo})), 4)`,
        },
        settleStatus:
          row['agentHasSettled'] === 1
            ? `已结算-${row['settleType'] === '' ? '未交收' : '已交收'}`
            : row['agentHasSettled'] === 0
            ? '未结算'
            : '',
        settlePeriod:
          row['agentHasSettled'] === 1 || row['agentHasSettled'] === 0
            ? `${row['startDate']} - ${row['endDate']}`
            : '',
      };

      const addedRow = worksheet.addRow(convertedRow);
      let fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E2EFDA' },
        bgColor: { argb: 'E2EFDA' },
      };
      let border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      if (row['ccy'] === 'PHP') {
        fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'f4f7c8' },
          bgColor: { argb: 'f4f7c8' },
        };
        border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      if (row['ccy'] === 'RMB') {
        fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'c8e7f7' },
          bgColor: { argb: 'c8e7f7' },
        };
        border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      const arr = Array.from({ length: 14 }, (_, j) => j + 1);
      arr.map((x) => (worksheet.getCell(worksheet.rowCount, x).fill = fill));
      arr.map(
        (x) => (worksheet.getCell(worksheet.rowCount, x).border = border)
      );
      if (i < data.length - 1 && data[i]['ccy'] !== data[i + 1]['ccy']) {
        const suSumRow = {
          ccy: '',
          mc: '合计：',
          agentName: '',
          cqAccount: '',
          cgAccount: '',
          winlose: { formula: `SUM(F${startRowNo}:F${rowNo})` },
          wash: { formula: `SUM(G${startRowNo}:G${rowNo})` },
          tip: { formula: `SUM(H${startRowNo}:H${rowNo})` },
          agentPercent: '',
          agentCommission: '',
          agentCommissionAmount: { formula: `SUM(K${startRowNo}:K${rowNo})` },
          commissionDiff: '',
          commissionDiffAmount: { formula: `SUM(M${startRowNo}:M${rowNo})` },
          actualSettle: { formula: `SUM(N${startRowNo}:N${rowNo})` },
          settleStatus: '',
        };
        worksheet.addRow(suSumRow);
        emptyRowCount += 1;
        startRowNo = rowNo + 2;
      }
    }

    // summary row
    const count = data.length;
    const sumRowNo = count + 1 + emptyRowCount;
    const settledRowNo = settledRowCount + 1;

    const sumRow = {
      ccy: '',
      mc: '合计：',
      agentName: '',
      cqAccount: '',
      cgAccount: '',
      winlose: { formula: `SUM(F${startRowNo}:F${sumRowNo})` },
      wash: { formula: `SUM(G${startRowNo}:G${sumRowNo})` },
      tip: { formula: `SUM(H${startRowNo}:H${sumRowNo})` },
      agentPercent: '',
      agentCommission: '',
      agentCommissionAmount: { formula: `SUM(K${startRowNo}:K${sumRowNo})` },
      commissionDiff: '',
      commissionDiffAmount: { formula: `SUM(M${startRowNo}:M${sumRowNo})` },
      actualSettle: { formula: `SUM(N${startRowNo}:N${sumRowNo})` },
      settleStatus: '',
    };
    const addedSum = worksheet.addRow(sumRow);

    // 已交收
    const sumRow2 = {
      ccy: '',
      mc: '',
      agentName: '',
      cqAccount: '',
      cgAccount: '',
      winlose: '',
      wash: '',
      tip: '',
      agentPercent: '',
      agentCommission: '',
      agentCommissionAmount: '',
      commissionDiff: '',
      commissionDiffAmount: '已交收',
      actualSettle:
        settledRowCount === 0
          ? 0
          : { formula: `-SUM(N${startRowNo}:N${settledRowNo})` },
      settleStatus: '',
    };
    worksheet.addRow(sumRow2);

    // 未交收
    const sumRow3 = {
      ccy: '',
      mc: '',
      agentName: '',
      cqAccount: '',
      cgAccount: '',
      winlose: '',
      wash: '',
      tip: '',
      agentPercent: '',
      agentCommission: '',
      agentCommissionAmount: '',
      commissionDiff: '',
      commissionDiffAmount: '未交收',
      actualSettle: { formula: `SUM(N${sumRowNo + 1},N${sumRowNo + 2})` },
      settleStatus: '',
    };
    worksheet.addRow(sumRow3);
  });

  // write to a new buffer
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer]);
  const objectURL = URL.createObjectURL(blob);
  // Auto download
  const link = document.createElement('a');
  link.href = objectURL;
  link.setAttribute('download', 'report.xlsx');
  // Append to html link element page
  document.body.appendChild(link);
  // Start download
  link.click();
  // Clean up and remove the link
  link?.parentNode.removeChild(link);
};

export default generateExcelFile;
