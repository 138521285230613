import moment from 'moment';

const ExcelJS = require('exceljs');

const generateExcelFile = async (params: any) => {
  const { data: allData } = params;

  console.log(allData);

  const workbook = new ExcelJS.Workbook();
  if (allData) {
    const worksheet = workbook.addWorksheet('总表');
    worksheet.columns = [
      { header: '起始时间', key: 'startDate', width: 20 },
      { header: '截止时间', key: 'endDate', width: 20 },
      { header: '币种', key: 'ccy', width: 13 },
      { header: '类型', key: 'mc', width: 13 },
      { header: 'CG户口', key: 'cgAccount', width: 13 },
      {
        header: '投注输赢',
        key: 'winlose',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '转码',
        key: 'wash',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '小费',
        key: 'tip',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '代理占成',
        key: 'agentPercent',
        width: 13,
        style: { numFmt: '0%' },
      },
      {
        header: '公司占成',
        key: 'company1Percent',
        width: 13,
        style: { numFmt: '0%' },
      },
      {
        header: 'SLE占成',
        key: 'company2Percent',
        width: 13,
        style: { numFmt: '0%' },
      },
      {
        header: '代理佣金比',
        key: 'agentCommission',
        width: 13,
        style: { numFmt: '0%' },
      },
      {
        header: '佣金',
        key: 'agentCommissionAmount',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '股东佣金差比',
        key: 'commissionDiff',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '股东佣金差',
        key: 'commissionDiffAmount',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '代理应收付',
        key: 'actualSettle',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: '公司输赢',
        key: 'company1Amount',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      {
        header: 'SLE输赢',
        key: 'company2Amount',
        width: 13,
        style: { numFmt: 'General;[Red]-General' },
      },
      { header: 'AG结算时间', key: 'settledAt', width: 20 },
      { header: '结算方式', key: 'settleType', width: 13 },
      { header: '出入款户口', key: 'transferAccount', width: 13 },
      { header: '备注', key: 'comment', width: 13 },
    ];
    worksheet.getRow(1).height = 30;
    [
      'A1',
      'B1',
      'C1',
      'D1',
      'E1',
      'F1',
      'G1',
      'H1',
      'I1',
      'J1',
      'K1',
      'L1',
      'M1',
      'N1',
      'O1',
      'P1',
      'Q1',
      'R1',
      'S1',
      'T1',
      'U1',
      'V1',
    ].map((key) => {
      worksheet.getCell(key).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D9D9D9' },
        bgColor: { argb: 'D9D9D9' },
      };
      worksheet.getCell(key).border = {
        top: { style: 'medium' },
        left: { style: 'medium' },
        bottom: { style: 'medium' },
        right: { style: 'medium' },
      };
    });
    let startRowNo = 2;
    let emptyRowCount = 0;

    for (let i = 0; i < allData.length; i++) {
      const { settledAt, settleData: row } = allData[i];
      const rowNo = i + 2 + emptyRowCount;

      let convertedRow = {};

      convertedRow = {
        startDate: row['startDate'],
        endDate: row['endDate'],
        ccy: row['ccy'],
        mc: row['mc'] === 'P' ? '配码上分' : row['mc'],
        cgAccount: row['cgAccount'],
        winlose: Number((row['winlose'] / 10000).toFixed(4)),
        wash: Number((row['wash'] / 10000).toFixed(4)),
        tip: Number((row['tip'] / 10000).toFixed(4)),
        agentPercent: Number((row['agentPercent'] / 100).toFixed(2)),
        company1Percent: Number((row['company1Percent'] / 100).toFixed(2)),
        company2Percent: Number((row['company2Percent'] / 100).toFixed(2)),
        agentCommission: Number((row['agentCommission'] / 100).toFixed(4)),
        agentCommissionAmount: { formula: `ROUND(G${rowNo}*L${rowNo}, 4)` },
        commissionDiff: Number((row['commissionDiff'] / 100).toFixed(4)),
        commissionDiffAmount: { formula: `ROUND(G${rowNo}*N${rowNo}, 4)` },
        actualSettle: {
          formula: `ROUND(IF(D${rowNo}="C",(F${rowNo}*I${rowNo}+M${rowNo}+O${rowNo}),IF(D${rowNo}="M",-(F${rowNo}*(100%-I${rowNo})-M${rowNo}-O${rowNo}+H${rowNo}),M${rowNo})), 4)`,
        },
        company1Amount: {
          formula: `ROUND((F${rowNo}*J${rowNo}-M${rowNo}-O${rowNo}), 4)`,
        },
        company2Amount: {
          formula: `ROUND((F${rowNo}*K${rowNo}+H${rowNo}), 4)`,
        },
        settledAt: moment(settledAt).format('YYYY-MM-DD HH:mm:ss'),
        settleType: row['settleType'],
        transferAccount: row['transferAccount'],
        comment: row['comment'],
      };

      const addedRow = worksheet.addRow(convertedRow);
      let fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E2EFDA' },
        bgColor: { argb: 'E2EFDA' },
      };
      let border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      if (row['ccy'] === 'PHP') {
        fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'f4f7c8' },
          bgColor: { argb: 'f4f7c8' },
        };
        border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      if (row['ccy'] === 'RMB') {
        fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'c8e7f7' },
          bgColor: { argb: 'c8e7f7' },
        };
        border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      const arr = Array.from({ length: 22 }, (_, j) => j + 1);
      arr.map((x) => (worksheet.getCell(worksheet.rowCount, x).fill = fill));
      arr.map(
        (x) => (worksheet.getCell(worksheet.rowCount, x).border = border)
      );
      if (
        i < allData.length - 1 &&
        allData[i]['ccy'] !== allData[i + 1]['ccy']
      ) {
        const suSumRow = {
          startDate: '',
          endDate: '',
          ccy: '',
          mc: '合计：',
          cgAccount: '',
          winlose: { formula: `SUM(F${startRowNo}:F${rowNo})` },
          wash: { formula: `SUM(G${startRowNo}:G${rowNo})` },
          tip: { formula: `SUM(H${startRowNo}:H${rowNo})` },
          agentPercent: '',
          company1Percent: '',
          company2Percent: '',
          agentCommission: '',
          agentCommissionAmount: { formula: `SUM(M${startRowNo}:M${rowNo})` },
          commissionDiff: '',
          commissionDiffAmount: { formula: `SUM(O${startRowNo}:O${rowNo})` },
          actualSettle: { formula: `SUM(P${startRowNo}:P${rowNo})` },
          company1Amount: { formula: `SUM(Q${startRowNo}:Q${rowNo})` },
          company2Amount: { formula: `SUM(R${startRowNo}:R${rowNo})` },
          settledAt: '',
          settleType: '',
          transferAccount: '',
          comment: '',
        };
        worksheet.addRow(suSumRow);
        emptyRowCount += 1;
        startRowNo = rowNo + 2;
      }
    }

    // summary row
    const count = allData.length;
    const sumRowNo = count + 1 + emptyRowCount;
    const sumRow = {
      startDate: '',
      endDate: '',
      ccy: '',
      mc: '合计：',
      cgAccount: '',
      winlose: { formula: `SUM(F${startRowNo}:F${sumRowNo})` },
      wash: { formula: `SUM(G${startRowNo}:G${sumRowNo})` },
      tip: { formula: `SUM(H${startRowNo}:H${sumRowNo})` },
      agentPercent: '',
      company1Percent: '',
      company2Percent: '',
      agentCommission: '',
      agentCommissionAmount: { formula: `SUM(M${startRowNo}:M${sumRowNo})` },
      commissionDiff: '',
      commissionDiffAmount: { formula: `SUM(O${startRowNo}:O${sumRowNo})` },
      actualSettle: { formula: `SUM(P${startRowNo}:P${sumRowNo})` },
      company1Amount: { formula: `SUM(Q${startRowNo}:Q${sumRowNo})` },
      company2Amount: { formula: `SUM(R${startRowNo}:R${sumRowNo})` },
      settledAt: '',
      settleType: '',
      transferAccount: '',
      comment: '',
    };
    const addedSum = worksheet.addRow(sumRow);
  }

  // write to a new buffer
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer]);
  const objectURL = URL.createObjectURL(blob);
  // Auto download
  const link = document.createElement('a');
  link.href = objectURL;
  link.setAttribute('download', 'report.xlsx');
  // Append to html link element page
  document.body.appendChild(link);
  // Start download
  link.click();
  // Clean up and remove the link
  link?.parentNode.removeChild(link);
};

export default generateExcelFile;
