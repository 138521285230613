import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { TextField, TableRow, TableCell } from '@mui/material';
import moment from 'moment-timezone';

const useStyles = makeStyles(() => ({
  root: {},
}));

interface Props {
  agentData?: any;
  upAmount?: any;
  actionType?: string;
}

const DisplayRow: React.FC<Props> = ({
  agentData,
  upAmount,
  actionType,
}: any) => {
  const classes = useStyles();

  const displayAmount =
    upAmount >= 10000 ? Number((upAmount / 10000).toFixed(4)) + '万' : upAmount;
  return (
    <>
      <TableRow hover key={agentData.agcode}>
        <TableCell>{agentData.cgAccount}</TableCell>
        <TableCell>
          {actionType === 'deposit' ? displayAmount : '-' + displayAmount}
        </TableCell>
        <TableCell>{actionType === 'deposit' ? '上分' : '下分'}</TableCell>
      </TableRow>
    </>
  );
};

export default DisplayRow;
