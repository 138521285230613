import React, { useState, useContext } from 'react';
import { DataGrid, GridApi, GridCellValue, GridColDef } from '@mui/x-data-grid';
import {
  Button,
  Box,
  CircularProgress,
  Typography,
  Theme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import agApi from '../../api';
import { makeStyles } from '@mui/styles';
import { SessionContext } from '../../context/session';

const useStyles = makeStyles((theme: Theme) => ({
  circularProgress: {
    marginLeft: '20px',
  },
}));

const Downloading = () => {
  const classes = useStyles();
  return (
    <Box
      position="relative"
      display="inline-flex"
      className={classes.circularProgress}
      style={{ marginLeft: 20, marginRight: 20, marginBottom: 20 }}
    >
      <CircularProgress />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          处理中
        </Typography>
      </Box>
    </Box>
  );
};

const Pending: React.FC<any> = (props: any) => {
  const { refreshData } = props;
  const [pageSize, setPageSize] = useState(5);

  const { mutateAsync: confirmUpDown } = agApi('confirmUpDown');

  const [open, setOpen] = useState(false);
  const [updownDetail, setUpdownDetail] = useState('');
  const [updownId, setUpdownId] = useState();
  const [downloading, setDownloading] = useState(false);
  const sessionContext = useContext(SessionContext);

  const columns: GridColDef[] = [
    {
      headerName: '创建时间',
      field: 'createdAt',
      width: 160,
    },
    {
      headerName: 'CG户口',
      field: 'cgAccount',
    },
    {
      headerName: '类型',
      field: 'action',
      align: 'center',
      width: 60,
    },
    {
      headerName: '操作人',
      field: 'createdBy',
      align: 'center',
      width: 70,
    },
    {
      headerName: '上下分',
      field: 'amount',
      align: 'right',
      width: 80,
      type: 'number',
    },
    {
      field: 'confirm',
      headerName: sessionContext.level === 9 ? '确认' : '',
      sortable: false,
      renderCell: (params: any) => {
        if (sessionContext.level !== 9) {
          return <></>;
        }
        const onClick = (e: any) => {
          // don't select this row after clicking
          e.stopPropagation();

          const api: GridApi = params.api;
          const thisRow: Record<string, GridCellValue> = {};

          api
            .getAllColumns()
            .filter((c: any) => c.field !== '__check__' && !!c)
            .forEach(
              (c: any) =>
                (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          const detail = `创建时间：${thisRow.createdAt}\nCG户口:${thisRow.cgAccount}\nCG户口:${thisRow.cgAccount}\n类型:${thisRow.action}\n操作人:${thisRow.createdBy}\n分数:${thisRow.amount}`;
          setUpdownDetail(detail);
          setUpdownId(params.id);
          setOpen(true);
        };

        return (
          <Button color="secondary" variant="contained" onClick={onClick}>
            确认上下分
          </Button>
        );
      },
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    setDownloading(true);
    await confirmUpDown({ updownId });
    await refreshData();
    setDownloading(false);
    setOpen(false);
  };

  return (
    <div style={{ height: 220, width: '100%' }}>
      <DataGrid
        rows={props.data}
        columns={columns}
        rowsPerPageOptions={[5, 10, 20, 50]}
        rowHeight={36}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
        localeText={{
          noRowsLabel: '没有数据。',
          noResultsOverlayLabel: '未找到数据。',
          errorOverlayDefaultLabel: '发生错误。',

          // Density selector toolbar button text
          toolbarDensity: '表格密度',
          toolbarDensityLabel: '表格密度',
          toolbarDensityCompact: '紧密',
          toolbarDensityStandard: '标准',
          toolbarDensityComfortable: '稀疏',

          // Columns selector toolbar button text
          toolbarColumns: '列',
          toolbarColumnsLabel: '选择列',

          // Filters toolbar button text
          toolbarFilters: '筛选器',
          toolbarFiltersLabel: '显示筛选器',
          toolbarFiltersTooltipHide: '隐藏筛选器',
          toolbarFiltersTooltipShow: '显示筛选器',
          toolbarFiltersTooltipActive: (count: number) => `${count} 个筛选器`,

          // Export selector toolbar button text
          toolbarExport: '导出',
          toolbarExportLabel: '导出',
          toolbarExportCSV: '导出至CSV',
          // toolbarExportPrint: 'Print',

          // Columns panel text
          columnsPanelTextFieldLabel: '搜索列',
          columnsPanelTextFieldPlaceholder: '列名',
          columnsPanelDragIconLabel: '重排序列',
          columnsPanelShowAllButton: '显示所有',
          columnsPanelHideAllButton: '隐藏所有',

          // Filter panel text
          filterPanelAddFilter: '添加筛选器',
          filterPanelDeleteIconLabel: '删除',
          filterPanelOperators: '操作器',
          filterPanelOperatorAnd: '与',
          filterPanelOperatorOr: '或',
          filterPanelColumns: '列',
          filterPanelInputLabel: '值',
          filterPanelInputPlaceholder: '筛选值',

          // Filter operators text
          filterOperatorContains: '包含',
          filterOperatorEquals: '等于',
          filterOperatorStartsWith: '开始于',
          filterOperatorEndsWith: '结束于',
          filterOperatorIs: '是',
          filterOperatorNot: '不是',
          filterOperatorAfter: '在后面',
          filterOperatorOnOrAfter: '正在后面',
          filterOperatorBefore: '在前面',
          filterOperatorOnOrBefore: '正在前面',
          filterOperatorIsEmpty: '为空',
          filterOperatorIsNotEmpty: '不为空',
          // filterOperatorIsAnyOf: 'is any of',

          // Filter values text
          filterValueAny: '任何',
          filterValueTrue: '真',
          filterValueFalse: '假',

          // Column menu text
          columnMenuLabel: '菜单',
          columnMenuShowColumns: '显示',
          columnMenuFilter: '筛选器',
          columnMenuHideColumn: '隐藏',
          columnMenuUnsort: '恢复默认',
          columnMenuSortAsc: '升序',
          columnMenuSortDesc: '降序',

          // Column header text
          columnHeaderFiltersTooltipActive: (count: number) =>
            count !== 1 ? `${count} 个筛选器` : `${count} 个筛选器`,
          columnHeaderFiltersLabel: '显示筛选器',
          columnHeaderSortIconLabel: '排序',

          // Rows selected footer text
          footerRowSelected: (count: number) =>
            `共选中了${count.toLocaleString()}行`,

          // Total rows footer text
          footerTotalRows: '所有行:',

          // Total visible rows footer text
          footerTotalVisibleRows: (visibleCount, totalCount) =>
            `${visibleCount.toLocaleString()} / ${totalCount.toLocaleString()}`,

          // Checkbox selection text
          checkboxSelectionHeaderName: '多选框',
          // checkboxSelectionSelectAllRows: 'Select all rows',
          // checkboxSelectionUnselectAllRows: 'Unselect all rows',
          // checkboxSelectionSelectRow: 'Select row',
          // checkboxSelectionUnselectRow: 'Unselect row',

          // Boolean cell text
          booleanCellTrueLabel: '真',
          booleanCellFalseLabel: '假',

          // Actions cell more text
          // actionsCellMore: 'more',

          // Column pinning text
          // pinToLeft: 'Pin to left',
          // pinToRight: 'Pin to right',
          // unpin: 'Unpin',

          // Tree Data
          // treeDataGroupingHeaderName: 'Group',
          // treeDataExpand: 'see children',
          // treeDataCollapse: 'hide children',

          // Grouping columns
          // groupingColumnHeaderName: 'Group',
          // groupColumn: name => `Group by ${name}`,
          // unGroupColumn: name => `Stop grouping by ${name}`,

          // Master/detail
          // expandDetailPanel: 'Expand',
          // collapseDetailPanel: 'Collapse',
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'确认前请先核对数据'}
        </DialogTitle>
        <DialogContent>{updownDetail}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          {!downloading ? (
            <Button onClick={handleConfirm} autoFocus>
              确认
            </Button>
          ) : (
            <Downloading />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Pending;
