import React from 'react';
import { TextField, Grid, InputAdornment } from '@mui/material';
import { useField } from 'formik';
import FormTitle from './FormTitle';
import NumberFormat from 'react-number-format';

interface NumberFormatCustomProps {
  thousandSeparator: boolean;
  allowLeadingZeros: boolean;
  inputRef: (instance: NumberFormat<unknown> | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  autoFocus?: boolean;
  prefix?: string;
  suffix?: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const {
    inputRef,
    onChange,
    thousandSeparator = false,
    allowLeadingZeros = false,
    autoFocus,
    prefix,
    suffix,
    ...other
  } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={thousandSeparator}
      allowLeadingZeros={allowLeadingZeros}
      decimalScale={4}
      isNumericString
      allowEmptyFormatting
      autoFocus={autoFocus}
      prefix={prefix}
      suffix={suffix}
    />
  );
}

interface INumberField {
  name: string;
  value?: string;
  title?: string;
  label?: string;
  prefix?: string;
  suffix?: string;
  thousandseparator?: string;
  placeholder?: string;
  fullWidth?: boolean;
  tipTitle?: string;
  variant?: any;
  backgroundcolor?: string;
  inputProps?: any;
  onBlur?: () => void;
  onChange?: () => void;
  shrinkTitle?: boolean;
  submitFailed?: boolean;
  autoFocus?: boolean;
}

const FormNumberField: React.FC<INumberField> = (props: INumberField) => {
  const [field, meta] = useField(props);
  const {
    title,
    tipTitle,
    prefix,
    suffix,
    thousandseparator,
    submitFailed,
    name,
    inputProps,
  } = props;

  const formatInput = {
    inputComponent: NumberFormatCustom,
  } as any;

  return (
    <>
      <Grid container direction="column">
        {(title || tipTitle) && (
          <Grid item>
            <FormTitle title={title} tipTitle={tipTitle} />
          </Grid>
        )}
        <Grid item>
          <TextField
            type="text"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            error={meta.touched && meta.error ? true : false}
            InputProps={{
              ...inputProps,
              ...formatInput,
            }}
            inputProps={{
              prefix,
              suffix,
              thousandSeparator: thousandseparator,
            }}
            {...field}
            {...props}
          />
          {(submitFailed || meta.touched) && meta.error ? (
            <div
              style={{
                color: '#cd2d2d',
                fontSize: '16px',
                margin: '3px 3px 5px 5px',
              }}
              data-test-id={`input-error-${name}`}
            >
              {meta.error}
            </div>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default FormNumberField;
