import React, { useEffect, useState } from 'react';
import { Route, RouteProps, Navigate } from 'react-router-dom';
import axios from '../utils/axios';
import { CircularProgress } from '@mui/material';
import DashboardLayout from '../layouts/Dashboard';
import { SessionContext } from '../context/session';
import * as jwt from 'jsonwebtoken';

const PrivateRoute: React.FC<RouteProps> = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLogin, setIsLogin] = useState(true);
  const [level, setLevel] = useState(null);

  useEffect(() => {
    let mounted = true;

    const fetchUser = async () => {
      try {
        if (mounted) {
          const token = window.localStorage.getItem('token');
          if (token) {
            const response = await axios.get(
              process.env.REACT_APP_SERVER_BASE_URL + '/api/auth/current_user',
              {
                headers: { authorization: token },
              }
            );
            const { data } = response.data;

            if (data) {
              const payload = jwt.decode(token);
              setUser(payload['user']);
              setLevel(payload['level']);
              setIsLogin(true);
            } else {
              setIsLogin(false);
            }
          } else {
            setIsLogin(false);
          }
        }
      } catch (err) {
        setIsLogin(false);
      }
    };

    if (user === null) {
      fetchUser();
    }

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      {user === null && <CircularProgress />}
      {user !== null && isLogin === true && (
        <SessionContext.Provider value={{ user, level }}>
          <DashboardLayout>{children}</DashboardLayout>
        </SessionContext.Provider>
      )}
      {user === null && isLogin === false && <Navigate to="/login" />}
    </>
  );
};

export default PrivateRoute;
