/* eslint-disable newline-per-chained-call */
import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  card: {
    width: 380,
  },
  inner: {
    width: 320,
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: '#1976D2',
    color: 'black',
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  tooltip: {
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  },
}));

interface Props {
  className?: Record<string, unknown> | string;
  data: any;
}

const Results: React.FC<Props> = ({ className, data, ...rest }: any) => {
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Card className={classes.card}>
        <CardContent>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>币种</TableCell>
                    <TableCell>汇率</TableCell>
                    <TableCell>录入时间</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((forex: any) => {
                    let fromCcy = '';
                    let toCcy = '';
                    switch (forex.fromCcy) {
                      case 'HKD':
                        fromCcy = '港';
                        break;
                      case 'RMB':
                        fromCcy = '人';
                        break;
                      case 'PHP':
                        fromCcy = '披索';
                        break;
                      default:
                        fromCcy = forex.fromCcy;
                    }
                    switch (forex.toCcy) {
                      case 'HKD':
                        toCcy = '港';
                        break;
                      case 'RMB':
                        toCcy = '人';
                        break;
                      case 'PHP':
                        toCcy = '披索';
                        break;
                      default:
                        toCcy = forex.toCcy;
                    }

                    return (
                      <TableRow key={forex.forexId}>
                        <TableCell>
                          {fromCcy}兑{toCcy}
                        </TableCell>
                        <TableCell>{forex.rate}</TableCell>
                        <TableCell>
                          {new Date(forex.createdAt).toString().substr(16, 5)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions} />
      </Card>
    </div>
  );
};

export default Results;
