import React from 'react';
import { InputLabel, Box, FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import { DateInput, DateFormatProps } from '@blueprintjs/datetime';
import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/zh-cn';

interface IDateTimePicker {
  label?: string;
  onChange?: any;
  value?: any;
  inputFormat?: string;
}

const useStyles = makeStyles((theme: any) => ({
  label: {
    fontFamily: 'Avenir',
    fontSize: '12px',
    color: '#546e7a',
  },
  toolTip: {
    marginTop: 0,
  },
  tipTitle: {
    fontSize: '15px',
  },
}));

function momentFormatter(format: string): DateFormatProps {
  return {
    formatDate: (date: any) => moment(date).format(format),
    parseDate: (str: string) => moment(str, format).toDate(),
    placeholder: `${format}`,
  };
}

const DateTimePicker: React.FC<IDateTimePicker> = (props: IDateTimePicker) => {
  const { label, value, onChange, inputFormat } = props;

  return (
    <>
      <FormControl>
        <Box id="component-outlined" style={{ zIndex: 3 }}>
          <InputLabel
            htmlFor="component-outlined"
            shrink
            style={{ background: '#ffffff', paddingLeft: 5, paddingRight: 5 }}
          >
            {label}
          </InputLabel>
        </Box>
        <Box id="component-outlined" style={{ zIndex: 2 }}>
          <DateInput
            value={new Date(value)}
            {...momentFormatter(inputFormat)}
            timePrecision="second"
            onChange={onChange}
            inputProps={{
              style: {
                height: 55,
                // boxShadow: 'none',
                borderRadius: 5,
              },
            }}
            locale="zh-cn"
            localeUtils={MomentLocaleUtils}
          />
        </Box>
      </FormControl>
    </>
  );
};

export default DateTimePicker;
