import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Card,
  TableRow,
  TableCell,
  Divider,
  Theme,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form } from 'formik';
import FormTextField from '../../components/FormTextField';
import agApi from '../../api';
import * as Yup from 'yup';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  card: {
    width: 300,
  },
  inner: {
    width: 250,
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: '#1976D2',
    color: 'black',
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  tooltip: {
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  },
  circularProgress: {
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '10px',
  },
}));

interface Props {
  className?: Record<string, unknown> | string;
  open: boolean;
  setOpen: any;
}

const ImportAgent: React.FC<Props> = ({
  className,
  open,
  setOpen,
  ...rest
}: any) => {
  const classes = useStyles();
  const { mutateAsync: importAgent } = agApi('importAgent');
  const [submitFailed, setSubmitFailed] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const history = useNavigate();

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSave = (props: any) => {
    const { validateForm, setTouched, submitForm } = props;
    validateForm().then((error: any) => {
      if (Object.keys(error).length === 0) {
        setTouched({});
        setSubmitFailed(false);
      } else {
        setSubmitFailed(true);
      }
      submitForm();
    });
  };

  const initialValues = {
    agcode: '',
  };

  const validationSchema = Yup.object().shape({
    agcode: Yup.string()
      .transform((v: any) => (v === null ? '' : v))
      .required('请输入CG代码'),
  });

  const handleSubmit = async (values: any) => {
    setDownloading(true);
    const result = await importAgent(values);
    console.log(result);
    history('/agent/' + result.agentId);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={handleSubmit}
      >
        {(props: any) => {
          return (
            <Form translate="yes">
              <Dialog open={open} onClose={handleCancel}>
                <DialogTitle>导入代理</DialogTitle>
                <DialogContent>
                  <Card {...rest} className={clsx(classes.root, className)}>
                    <Divider />
                    <TableRow>
                      <TableCell>CG代码</TableCell>
                      <TableCell>
                        <FormTextField
                          name="agcode"
                          onChange={props.handleChange}
                          submitFailed={submitFailed}
                        />
                      </TableCell>
                    </TableRow>
                  </Card>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancel} color="primary">
                    取消
                  </Button>
                  {!downloading ? (
                    <Button
                      onClick={() => handleSave(props)}
                      color="primary"
                      autoFocus
                    >
                      导入
                    </Button>
                  ) : (
                    <Box
                      position="relative"
                      display="inline-flex"
                      className={classes.circularProgress}
                    >
                      <CircularProgress />
                      <Box
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography
                          variant="caption"
                          component="div"
                          color="textSecondary"
                        >
                          导入中
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </DialogActions>
              </Dialog>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ImportAgent;
