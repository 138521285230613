import { useQuery, useMutation } from 'react-query';
import axios from 'axios';
import { isEmpty } from 'lodash';

interface IApiList {
  [key: string]: {
    url: string;
    method: 'get' | 'GET' | 'post' | 'delete' | 'patch' | 'put';
    refreshFunc?: string;
    enabled?: boolean;
  };
}

interface IParam {
  [key: string]: unknown;
}

// get - Query, GET - Mutation
const apiList: IApiList = {
  getCurrentUser: {
    url: '/api/auth/current_user',
    method: 'get',
  },
  login: {
    url: '/api/auth/login',
    method: 'post',
  },
  logout: {
    url: '/api/auth/logout',
    method: 'GET',
  },
  getCurrencies: {
    url: '/api/forex',
    method: 'GET',
  },
  addCurrencies: {
    url: '/api/forex',
    method: 'put',
  },
  winloseReport: {
    url: '/api/reports/winloseReport',
    method: 'post',
  },
  getAgents: {
    url: '/api/agents',
    method: 'GET',
  },
  searchAgent: {
    url: '/api/agents/search',
    method: 'post',
  },
  getAgentById: {
    url: '/api/agent/:id',
    method: 'GET',
  },
  updateAgent: {
    url: '/api/agent/:id',
    method: 'post',
  },
  addAgent: {
    url: '/api/agent',
    method: 'put',
  },
  deleteAgent: {
    url: '/api/agent',
    method: 'delete',
  },
  importAgent: {
    url: '/api/reports/addNewAgent',
    method: 'post',
  },
  getLastSettlementByAgcode: {
    url: '/api/settlement/agcode/:agcode/last',
    method: 'GET',
  },
  getSettlementData: {
    url: '/api/settlement',
    method: 'post',
  },
  getSettlement: {
    url: '/api/settlement/:settlementId',
    method: 'GET',
  },
  addSettlement: {
    url: '/api/settlement',
    method: 'put',
  },
  getAgentSettlements: {
    url: '/api/settlements',
    method: 'post',
  },
  settleLastWeek: {
    url: '/api/settlements/settleLastWeek',
    method: 'post',
  },
  getCashList: {
    url: '/api/reports/getCashList',
    method: 'post',
  },
  getConfigs: {
    url: '/api/configs',
    method: 'GET',
  },
  getCashs: {
    url: '/api/cashs',
    method: 'GET',
  },
  updateCash: {
    url: '/api/cashs',
    method: 'patch',
  },
  forceUpdateCashs: {
    url: '/api/cashs/forceUpdate',
    method: 'post',
  },
  searchCashs: {
    url: '/api/cashs/search',
    method: 'post',
  },
  updateCashs: {
    url: '/api/cashs',
    method: 'post',
  },
  getAllUpAgcodes: {
    url: '/api/agents/allUpAgcodes',
    method: 'post',
  },
  changePassword: {
    url: '/api/users/password',
    method: 'patch',
  },
  deposit: {
    url: '/api/updown/deposit',
    method: 'post',
  },
  withdraw: {
    url: '/api/updown/withdraw',
    method: 'post',
  },
  getUpDownPending: {
    url: '/api/updown/pending',
    method: 'GET',
  },
  getUpDownCompleted: {
    url: '/api/updown/completed',
    method: 'GET',
  },
  confirmUpDown: {
    url: '/api/updown/confirm',
    method: 'post',
  },
  refreshBalances: {
    url: '/api/agents/refreshBalances',
    method: 'patch',
  },
};

const callFunc = async (funcName: string, params?: IParam, data?: any) => {
  try {
    let { url } = apiList[funcName];
    const { method } = apiList[funcName];
    if (params) {
      Object.keys(params).map((key) => {
        if (url.indexOf(':' + key) !== -1) {
          url = url.replace(':' + key, params[key] as string);
        }
        return true;
      });
    }

    if ((params === undefined || isEmpty(params)) && url.indexOf(':') !== -1) {
      url = url.substring(0, url.indexOf(':'));
    }

    const token = window.localStorage.getItem('token');

    const res = await axios({
      url: process.env.REACT_APP_SERVER_BASE_URL + url,
      method,
      data,
      headers: {
        authorization: token,
      },
    });
    return res.data.data;
  } catch (err) {
    if (err.response?.data?.error?.message) {
      console.log(funcName + ': ' + err.response.data.error.message);
      throw new Error(err.response.data.error.message);
    } else {
      console.log(funcName + ': ' + err.message);
      throw new Error(err.message);
    }
  }
};

const Query = (funcName: string, params?: IParam) => {
  const {
    data,
    error,
    failureCount,
    isError,
    isFetchedAfterMount,
    isFetching,
    isIdle,
    isLoading,
    isPlaceholderData,
    isPreviousData,
    isStale,
    isSuccess,
    refetch,
    remove,
    status,
  } = useQuery([funcName, params], () => callFunc(funcName, params), {
    // disable retry to avoid 4 times API call if failed
    retry: false,
    // diable refetchOnWindowFocus to avoid unnecessary API call on focus
    refetchOnWindowFocus: false,
  });

  return {
    data,
    error,
    failureCount,
    isError,
    isFetchedAfterMount,
    isFetching,
    isIdle,
    isLoading,
    isPlaceholderData,
    isPreviousData,
    isStale,
    isSuccess,
    refetch,
    remove,
    status,
  };
};

const Mutation = (funcName: string, params?: IParam) => {
  const {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  } = useMutation((postData: any) => callFunc(funcName, params, postData));

  return {
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  };
};

const agApi = (funcName: string, params?: IParam): any => {
  const { method } = apiList[funcName];
  if (method === 'get') {
    return Query(funcName, params);
  }
  return Mutation(funcName, params);
};

export default agApi;
