import Login from '../pages/login';

const AuthRoute = () => {
  return (
    <div>
      <Login />
    </div>
  );
};

export default AuthRoute;
