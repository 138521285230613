import React, { useEffect, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import {
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
} from '@mui/material';
import NativeSelect from '@mui/material/NativeSelect';
import moment from 'moment-timezone';
import { useForm, useWatch } from 'react-hook-form';
import { SessionContext } from '../../context/session';
import agApi from '../../api';

const useStyles = makeStyles(() => ({
  root: {},
}));

interface Props {
  settlement?: any;
  handleDialogClose?: any;
  updateSettlements?: any;
}

const settleTypeOptions = [
  {
    label: '',
    value: '',
  },
  {
    label: '上分',
    value: 'up',
  },
  {
    label: '下分',
    value: 'down',
  },
  {
    label: '现金转出',
    value: 'transfer',
  },
  {
    label: '上分+现金转出',
    value: 'upTransfer',
  },
  {
    label: '0交收',
    value: 'zero',
  },
];

const transferAccountOptions = [
  {
    label: '',
    value: '',
  },
  {
    label: 'M',
    value: 'M',
  },
  {
    label: 'T',
    value: 'T',
  },
  {
    label: 'FT',
    value: 'FT',
  },
];

const SettleRow: React.FC<Props> = ({
  settlement,
  handleDialogClose,
  updateSettlements,
}: any) => {
  const classes = useStyles();
  const { startDate, endDate, settleData } = settlement;
  const sessionContext = useContext(SessionContext);
  const { mutateAsync: addSettlement } = agApi('addSettlement', {
    agcode: settleData?.agcode,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      settledAt: settleData?.settledAt,
      settleType: settleData?.settleType,
      transferAccount: settleData?.transferAccount,
      comment: settleData?.comment,
      upAmount: settleData?.upAmount,
      transferAmount: settleData?.transferAmount,
    },
  });
  const onSubmit = async (values: any) => {
    const newSettlement = {
      ...settlement,
      settleData: {
        ...settlement.settleData,
        settleType: values.settleType,
        transferAccount: values.transferAccount,
        comment: values.comment,
        agentCommissionAmount,
        commissionDiffAmount,
        actualSettle,
        company1Amount,
        company2Amount,
        upAmount: values.upAmount,
        transferAmount: values.transferAmount,
      },
      settledAt: values.settledAt,
      operator: sessionContext.user,
    };

    const result = await addSettlement(newSettlement);
    updateSettlements(result.agentId);
    handleDialogClose(false);
  };

  const settleType = useWatch({
    control,
    name: 'settleType',
  });

  const isUpTransfer = settleType === 'upTransfer' ? true : false;
  const isIncludeTransfer =
    settleType === 'upTransfer' || settleType === 'transfer' ? true : false;

  const agentCommissionAmount = Number(
    (((settleData?.wash / 10000) * settleData?.agentCommission) / 100).toFixed(
      4
    )
  );

  const commissionDiffAmount = Number(
    (((settleData?.wash / 10000) * settleData?.commissionDiff) / 100).toFixed(4)
  );

  let actualSettle = 0;

  switch (settleData?.mc) {
    case 'C':
      actualSettle = Number(
        (
          Number(
            (settleData.winlose / 10000) * (settleData?.agentPercent / 100)
          ) +
          agentCommissionAmount +
          commissionDiffAmount
        ).toFixed(4)
      );
      break;
    case 'M':
      actualSettle = -Number(
        (
          Number(
            (settleData?.winlose / 10000) * (1 - settleData?.agentPercent / 100)
          ) -
          agentCommissionAmount -
          commissionDiffAmount +
          settleData?.tip / 10000
        ).toFixed(4)
      );
      break;
    case 'P':
      actualSettle = agentCommissionAmount;
      break;
    default:
  }

  const company1Amount = Number(
    (
      Number(
        (settleData?.winlose / 10000) * (settleData?.company1Percent / 100)
      ) -
      agentCommissionAmount -
      commissionDiffAmount
    ).toFixed(4)
  );

  const company2Amount = Number(
    (
      Number(
        (settleData?.winlose / 10000) * (settleData?.company2Percent / 100)
      ) + settleData?.tip
    ).toFixed(4)
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        结算明细
      </DialogTitle>

      <DialogContent>
        {Object.keys(settlement).length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>起始时间</TableCell>
                <TableCell>截止时间</TableCell>
                <TableCell>AG结算时间</TableCell>
                <TableCell>币种</TableCell>
                <TableCell>种类</TableCell>
                <TableCell>CG户口</TableCell>
                <TableCell>投注输赢</TableCell>
                <TableCell>转码</TableCell>
                <TableCell>小费</TableCell>
                <TableCell>代理占成</TableCell>
                {/* <TableCell>公司占成</TableCell>
                <TableCell>SLE占成</TableCell> */}
                <TableCell>代理佣金比</TableCell>
                <TableCell>佣金</TableCell>
                <TableCell>股东佣金差比</TableCell>
                <TableCell>股东佣金差</TableCell>
                <TableCell>代理应收付</TableCell>
                {/* <TableCell>公司输赢</TableCell>
                <TableCell>SLE输赢</TableCell> */}
                <TableCell>结算方式</TableCell>
                {isUpTransfer && (
                  <>
                    <TableCell>上分数</TableCell>
                    <TableCell>现金转出数</TableCell>
                  </>
                )}
                {isIncludeTransfer && <TableCell>出入款户口</TableCell>}
                <TableCell>备注</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {moment(startDate).format('YYYY-MM-DD HH:mm:ss')}
                </TableCell>
                <TableCell>
                  {moment(endDate).format('YYYY-MM-DD HH:mm:ss')}
                </TableCell>
                <TableCell>
                  <TextField
                    defaultValue={
                      settlement.settledAt
                        ? moment(settlement.settledAt).format(
                            'YYYY-MM-DD HH:mm:ss'
                          )
                        : ''
                    }
                    variant="filled"
                    style={{ width: '135px' }}
                    onKeyDown={(ev: any) => {
                      if (ev.key === 'Enter') {
                        ev.preventDefault();
                      }
                    }}
                    {...register('settledAt')}
                  />
                </TableCell>
                <TableCell>{settleData.ccy}</TableCell>
                <TableCell>{settleData.mc}</TableCell>
                <TableCell>{settleData.cgAccount}</TableCell>
                <TableCell>
                  {Number((settleData.winlose / 10000).toFixed(4))}
                </TableCell>
                <TableCell>
                  {Number((settleData.wash / 10000).toFixed(4))}
                </TableCell>
                <TableCell>
                  {Number((settleData.tip / 10000).toFixed(4))}
                </TableCell>
                <TableCell>{settleData.agentPercent}%</TableCell>
                {/* <TableCell>{settleData.company1Percent}%</TableCell>
                <TableCell>{settleData.company2Percent}%</TableCell> */}
                <TableCell>{settleData.agentCommission}%</TableCell>
                <TableCell>{agentCommissionAmount}</TableCell>
                <TableCell>{settleData.commissionDiff}</TableCell>
                <TableCell>{commissionDiffAmount}</TableCell>
                <TableCell>{actualSettle}</TableCell>
                {/* <TableCell>{company1Amount}</TableCell>
                <TableCell>{company2Amount}</TableCell> */}
                <TableCell>
                  <NativeSelect
                    required
                    fullWidth
                    style={{ width: '70px' }}
                    {...register('settleType')}
                  >
                    <option key="placeholder" value="" disabled>
                      请选择
                    </option>
                    {settleTypeOptions.map((option: any, i: any) => (
                      <option key={`${option.value}-${i}`} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </NativeSelect>
                </TableCell>
                {isUpTransfer && (
                  <>
                    <TableCell>
                      <TextField
                        defaultValue={settleData.upAmount}
                        variant="filled"
                        style={{ width: '100px' }}
                        onKeyDown={(ev: any) => {
                          if (ev.key === 'Enter') {
                            ev.preventDefault();
                          }
                        }}
                        {...register('upAmount')}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        defaultValue={settleData.transferAmount}
                        variant="filled"
                        style={{ width: '100px' }}
                        onKeyDown={(ev: any) => {
                          if (ev.key === 'Enter') {
                            ev.preventDefault();
                          }
                        }}
                        {...register('transferAmount')}
                      />
                    </TableCell>
                  </>
                )}
                {isIncludeTransfer && (
                  <TableCell>
                    <NativeSelect
                      required
                      fullWidth
                      style={{ width: '100px' }}
                      {...register('transferAccount')}
                    >
                      <option key="placeholder" value="" disabled>
                        请选择
                      </option>
                      {transferAccountOptions.map((option: any, i: any) => (
                        <option
                          key={`${option.value}-${i}`}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </NativeSelect>
                  </TableCell>
                )}
                <TableCell>
                  <TextField
                    defaultValue={settleData.comment}
                    variant="filled"
                    style={{ width: '200px' }}
                    onKeyDown={(ev: any) => {
                      if (ev.key === 'Enter') {
                        ev.preventDefault();
                      }
                    }}
                    {...register('comment')}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          '此时间段没有数据需要结算'
        )}
      </DialogContent>
      {Object.keys(settlement).length > 0 ? (
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            variant="contained"
            color="primary"
          >
            取消
          </Button>
          <Button variant="contained" color="primary" type="submit">
            结算
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            variant="contained"
            color="primary"
          >
            取消
          </Button>
        </DialogActions>
      )}
    </form>
  );
};

export default SettleRow;
