import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Card,
  TableRow,
  TableCell,
  Divider,
  Theme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form } from 'formik';
import FormNumberField from '../../components/FormNumberField';
import FormTextField from '../../components/FormTextField';
import FormSwitchGreen from '../../components/FormSwitchGreen';
import FormNativeSelect from '../../components/FormNativeSelect';
import agApi from '../../api';
import { SessionContext } from '../../context/session';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  card: {
    width: 300,
  },
  inner: {
    width: 250,
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: '#1976D2',
    color: 'black',
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  tooltip: {
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  },
}));

interface Props {
  className?: Record<string, unknown> | string;
  open: boolean;
  setOpen: any;
  updateFlag: boolean;
  agent: any;
  setAgent: any;
}

const ccyOptions = [
  {
    label: 'HKD',
    value: 'HKD',
  },
  {
    label: 'RMB',
    value: 'RMB',
  },
  {
    label: 'PHP',
    value: 'PHP',
  },
];

const mcOptions = [
  {
    label: 'M',
    value: 'M',
  },
  {
    label: 'C',
    value: 'C',
  },
  {
    label: '配码上分',
    value: 'P',
  },
];

const statusOptions = [
  {
    label: '锁定',
    value: -1,
  },
  {
    label: '禁用',
    value: 0,
  },
  {
    label: '启用',
    value: 1,
  },
];

const EditAgent: React.FC<Props> = ({
  className,
  open,
  setOpen,
  updateFlag,
  agent,
  setAgent,
  ...rest
}: any) => {
  const classes = useStyles();
  const { mutateAsync: updateAgent } = agApi('updateAgent');
  const { mutateAsync: addAgent } = agApi('addAgent');
  const { mutateAsync: deleteAgent } = agApi('deleteAgent');
  const [submitFailed, setSubmitFailed] = useState(false);
  const history = useNavigate();
  const sessionContext = useContext(SessionContext);

  const handleDelete = async (agentId: string | number) => {
    await deleteAgent({ agentId });
    history('/agents');
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSave = (props: any) => {
    const { validateForm, setTouched, submitForm } = props;
    validateForm().then((error: any) => {
      if (Object.keys(error).length === 0) {
        setTouched({});
        setSubmitFailed(false);
      } else {
        setSubmitFailed(true);
      }
      submitForm();
    });
  };

  const initialValues = {
    display: agent?.display,
    agentId: agent?.agentId,
    agentName: agent?.agentName,
    cqAccount: agent?.cqAccount,
    cgAccount: agent?.cgAccount,
    agcode: agent?.agcode,
    allias: agent?.allias,
    isCompanyAccount: agent?.isCompanyAccount,
    hadDownLine: agent?.hadDownLine,
    isAgent: agent?.isAgent,
    level: !updateFlag ? 2 : agent?.level,
    upAgcode: !updateFlag ? 0 : agent?.upAgcode,
    balance: agent?.balance,
    ccy: agent?.ccy,
    mc: agent?.mc,
    status: !updateFlag ? 1 : agent?.status,
    agentPercent: agent?.agentPercent,
    agentCommission: agent?.agentCommission,
    company1Percent: agent?.company1Percent,
    company1Commission: agent?.company1Commission,
    company2Percent: agent?.company2Percent,
    company3Percent: agent?.company3Percent,
    contact: agent?.contact,
    agentComment: agent?.agentComment,
  };

  const handleSubmit = async (values: any) => {
    let result = new Object();
    if (updateFlag) {
      result = await updateAgent(values);
    } else {
      result = await addAgent(values);
    }

    setAgent(result);
    setOpen(false);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(props: any) => {
          const { values } = props;
          const { agentPercent, company2Percent } = values;
          console.log(agentPercent, company2Percent);
          values.company1Percent = Number(
            Number(
              100 - Number(agentPercent) - Number(company2Percent)
            ).toFixed(2)
          );

          return (
            <Form translate="yes">
              <Dialog open={open} onClose={handleCancel}>
                <DialogTitle>{!updateFlag ? '添加' : '修改'}</DialogTitle>
                <DialogContent>
                  <Card {...rest} className={clsx(classes.root, className)}>
                    <Divider />
                    <TableRow>
                      <TableCell>显示在代理列表？</TableCell>
                      <TableCell>
                        <FormSwitchGreen
                          name="display"
                          direction="left"
                          onChange={props.handleChange}
                          submitFailed={submitFailed}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>户名</TableCell>
                      <TableCell>
                        <FormTextField
                          name="agentName"
                          onChange={props.handleChange}
                          submitFailed={submitFailed}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>代理户口</TableCell>
                      <TableCell>
                        <FormTextField
                          name="cqAccount"
                          onChange={props.handleChange}
                          submitFailed={submitFailed}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>CG户口</TableCell>
                      <TableCell>
                        <FormTextField
                          name="cgAccount"
                          onChange={props.handleChange}
                          submitFailed={submitFailed}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>cg代号</TableCell>
                      <TableCell>
                        <FormNumberField
                          name="agcode"
                          onChange={props.handleChange}
                          submitFailed={submitFailed}
                        />
                      </TableCell>
                    </TableRow>
                    {sessionContext.level === 9 && (
                      <TableRow>
                        <TableCell>公司账号？</TableCell>
                        <TableCell>
                          <FormSwitchGreen
                            name="isCompanyAccount"
                            direction="left"
                            onChange={props.handleChange}
                            submitFailed={submitFailed}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>代理等级？</TableCell>
                      <TableCell>
                        <FormNumberField
                          name="level"
                          onChange={props.handleChange}
                          submitFailed={submitFailed}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>上线cg代号？</TableCell>
                      <TableCell>
                        <FormNumberField
                          name="upAgcode"
                          onChange={props.handleChange}
                          submitFailed={submitFailed}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>币种</TableCell>
                      <TableCell>
                        <FormNativeSelect
                          name="ccy"
                          placeholder="Please select"
                          onChange={props.handleChange}
                          submitFailed={props.submitFailed}
                          options={ccyOptions}
                          defaultValue=""
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>M/C种类</TableCell>
                      <TableCell>
                        <FormNativeSelect
                          name="mc"
                          placeholder="Please select"
                          onChange={props.handleChange}
                          submitFailed={props.submitFailed}
                          options={mcOptions}
                          defaultValue=""
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>代理占成</TableCell>
                      <TableCell>
                        <FormNumberField
                          name="agentPercent"
                          onChange={props.handleChange}
                          submitFailed={submitFailed}
                          suffix="%"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>代理佣金比</TableCell>
                      <TableCell>
                        <FormNumberField
                          name="agentCommission"
                          onChange={props.handleChange}
                          submitFailed={submitFailed}
                          suffix="%"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>公司占成</TableCell>
                      <TableCell>
                        <FormNumberField
                          name="company1Percent"
                          onChange={props.handleChange}
                          submitFailed={submitFailed}
                          suffix="%"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>SLE占成</TableCell>
                      <TableCell>
                        <FormNumberField
                          name="company2Percent"
                          onChange={props.handleChange}
                          submitFailed={submitFailed}
                          suffix="%"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>股东佣金差比</TableCell>
                      <TableCell>
                        <FormNumberField
                          name="commissionDiff"
                          onChange={props.handleChange}
                          submitFailed={submitFailed}
                          suffix="%"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>联系方式</TableCell>
                      <TableCell>
                        <FormTextField
                          name="contact"
                          onChange={props.handleChange}
                          submitFailed={submitFailed}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>备注</TableCell>
                      <TableCell>
                        <FormTextField
                          name="agentComment"
                          onChange={props.handleChange}
                          submitFailed={submitFailed}
                        />
                      </TableCell>
                    </TableRow>
                  </Card>
                </DialogContent>
                <DialogActions>
                  {updateFlag && (
                    <Button
                      onClick={() => handleDelete(values.agentId)}
                      color="primary"
                    >
                      删除此代理
                    </Button>
                  )}
                  <Button onClick={handleCancel} color="primary">
                    取消
                  </Button>
                  <Button
                    onClick={() => handleSave(props)}
                    color="primary"
                    autoFocus
                  >
                    保存
                  </Button>
                </DialogActions>
              </Dialog>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditAgent;
