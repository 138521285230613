import React, { useState, useCallback, useEffect } from 'react';
import { DataGrid, gridVisibleSortedRowIdsSelector } from '@mui/x-data-grid';
import agApi from '../../api';

const columns = (withdrawFee: number): any => [
  {
    headerName: '创建时间',
    field: 'createdAt',
    width: 160,
  },
  {
    headerName: '类型',
    field: 'mc',
    align: 'center',
    width: 60,
  },
  {
    headerName: '匹配代理占成',
    field: 'agentPercent',
  },
  {
    headerName: 'CG户口',
    field: 'cgAccount',
  },
  {
    headerName: '操作',
    field: 'action',
    align: 'center',
    width: 60,
  },
  {
    headerName: '操作人',
    field: 'operator',
    align: 'center',
    width: 70,
  },
  {
    headerName: '币种',
    field: 'ccy',
    align: 'center',
    width: 60,
  },
  {
    headerName: '上下分',
    field: 'amount',
    align: 'right',
    width: 80,
    type: 'number',
  },
  {
    headerName: '手续费' + withdrawFee + '%',
    field: 'handlingFee',
    editable: true,
    align: 'right',
    width: 105,
    type: 'number',
  },
  {
    headerName: '预支',
    field: 'preWithdraw',
    editable: true,
    align: 'right',
    width: 80,
    type: 'number',
  },
  {
    headerName: '实际出入款',
    field: 'actualSettle',
    editable: true,
    align: 'right',
    width: 100,
    type: 'number',
  },
  {
    headerName: '出入款户口',
    field: 'transferAccount',
    editable: true,
    type: 'singleSelect',
    valueOptions: ['M', 'T', 'FT', ''],
    align: 'center',
  },
  {
    headerName: '备注',
    field: 'comment',
    editable: true,
    width: 180,
  },
];
const CashDetail: React.FC<any> = (props: any) => {
  const { setVisibleRows, withdrawFee } = props;
  const [pageSize, setPageSize] = useState(10);

  const { mutateAsync: updateCash } = agApi('updateCash');

  const handleCellEditCommit = useCallback((params: any) => {
    const newData = new Object();
    newData['cashId'] = params.id;
    const value = params.value;
    newData[params.field] = value;
    updateCash(newData);
  }, []);

  return (
    <div style={{ height: 500, width: '100%' }}>
      <DataGrid
        rows={props.cashList}
        columns={columns(withdrawFee)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        rowHeight={36}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
        // editRowsModel={editRowsModel}
        // cellEditCommit={handleEditRowsModelChange}
        onCellEditCommit={handleCellEditCommit}
        onStateChange={(state: any) => {
          const newRows = gridVisibleSortedRowIdsSelector(state);
          setVisibleRows(newRows);
        }}
        localeText={{
          noRowsLabel: '没有数据。',
          noResultsOverlayLabel: '未找到数据。',
          errorOverlayDefaultLabel: '发生错误。',

          // Density selector toolbar button text
          toolbarDensity: '表格密度',
          toolbarDensityLabel: '表格密度',
          toolbarDensityCompact: '紧密',
          toolbarDensityStandard: '标准',
          toolbarDensityComfortable: '稀疏',

          // Columns selector toolbar button text
          toolbarColumns: '列',
          toolbarColumnsLabel: '选择列',

          // Filters toolbar button text
          toolbarFilters: '筛选器',
          toolbarFiltersLabel: '显示筛选器',
          toolbarFiltersTooltipHide: '隐藏筛选器',
          toolbarFiltersTooltipShow: '显示筛选器',
          toolbarFiltersTooltipActive: (count: number) => `${count} 个筛选器`,

          // Export selector toolbar button text
          toolbarExport: '导出',
          toolbarExportLabel: '导出',
          toolbarExportCSV: '导出至CSV',
          // toolbarExportPrint: 'Print',

          // Columns panel text
          columnsPanelTextFieldLabel: '搜索列',
          columnsPanelTextFieldPlaceholder: '列名',
          columnsPanelDragIconLabel: '重排序列',
          columnsPanelShowAllButton: '显示所有',
          columnsPanelHideAllButton: '隐藏所有',

          // Filter panel text
          filterPanelAddFilter: '添加筛选器',
          filterPanelDeleteIconLabel: '删除',
          filterPanelOperators: '操作器',
          filterPanelOperatorAnd: '与',
          filterPanelOperatorOr: '或',
          filterPanelColumns: '列',
          filterPanelInputLabel: '值',
          filterPanelInputPlaceholder: '筛选值',

          // Filter operators text
          filterOperatorContains: '包含',
          filterOperatorEquals: '等于',
          filterOperatorStartsWith: '开始于',
          filterOperatorEndsWith: '结束于',
          filterOperatorIs: '是',
          filterOperatorNot: '不是',
          filterOperatorAfter: '在后面',
          filterOperatorOnOrAfter: '正在后面',
          filterOperatorBefore: '在前面',
          filterOperatorOnOrBefore: '正在前面',
          filterOperatorIsEmpty: '为空',
          filterOperatorIsNotEmpty: '不为空',
          // filterOperatorIsAnyOf: 'is any of',

          // Filter values text
          filterValueAny: '任何',
          filterValueTrue: '真',
          filterValueFalse: '假',

          // Column menu text
          columnMenuLabel: '菜单',
          columnMenuShowColumns: '显示',
          columnMenuFilter: '筛选器',
          columnMenuHideColumn: '隐藏',
          columnMenuUnsort: '恢复默认',
          columnMenuSortAsc: '升序',
          columnMenuSortDesc: '降序',

          // Column header text
          columnHeaderFiltersTooltipActive: (count: number) =>
            count !== 1 ? `${count} 个筛选器` : `${count} 个筛选器`,
          columnHeaderFiltersLabel: '显示筛选器',
          columnHeaderSortIconLabel: '排序',

          // Rows selected footer text
          footerRowSelected: (count: number) =>
            `共选中了${count.toLocaleString()}行`,

          // Total rows footer text
          footerTotalRows: '所有行:',

          // Total visible rows footer text
          footerTotalVisibleRows: (visibleCount, totalCount) =>
            `${visibleCount.toLocaleString()} / ${totalCount.toLocaleString()}`,

          // Checkbox selection text
          checkboxSelectionHeaderName: '多选框',
          // checkboxSelectionSelectAllRows: 'Select all rows',
          // checkboxSelectionUnselectAllRows: 'Unselect all rows',
          // checkboxSelectionSelectRow: 'Select row',
          // checkboxSelectionUnselectRow: 'Unselect row',

          // Boolean cell text
          booleanCellTrueLabel: '真',
          booleanCellFalseLabel: '假',

          // Actions cell more text
          // actionsCellMore: 'more',

          // Column pinning text
          // pinToLeft: 'Pin to left',
          // pinToRight: 'Pin to right',
          // unpin: 'Unpin',

          // Tree Data
          // treeDataGroupingHeaderName: 'Group',
          // treeDataExpand: 'see children',
          // treeDataCollapse: 'hide children',

          // Grouping columns
          // groupingColumnHeaderName: 'Group',
          // groupColumn: name => `Group by ${name}`,
          // unGroupColumn: name => `Stop grouping by ${name}`,

          // Master/detail
          // expandDetailPanel: 'Expand',
          // collapseDetailPanel: 'Collapse',
        }}
      />
    </div>
  );
};

export default CashDetail;
