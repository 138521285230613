import React from 'react';
import { Switch, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useField } from 'formik';

interface ISwitch {
  name: string;
  value?: string;
  label?: any;
  align?: string;
  colorType?: string;
  labelPlacement?: string;
  direction?: 'right' | 'left' | undefined;
  submitFailed?: boolean;
  onChange?: () => void;
}

type LabelPlacement = 'start' | 'bottom' | 'top' | 'end' | undefined;

const useStyles = makeStyles((theme: any) => {
  return {
    parent: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 10,
      alignItems: 'center',
    },
    root: {
      height: 39,
      padding: 8,
      margin: '0 10px 0 -6px',
    },
    switchBase: {
      padding: 10,
      color: '#ff6a00',
    },
    thumb: {
      width: 20,
      height: 20,
      backgroundColor: '#fff',
    },
    track: {
      background: 'linear-gradient(to right, #EAEDF3, #EAEDF3)',
      opacity: '1 !important',
      borderRadius: 20,
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#fff',
        textAlign: 'center',
      },
      '&:before': {
        left: 4,
        opacity: 0,
      },
      '&:after': {
        right: 4,
      },
    },
    checked: {
      '&$switchBase': {
        color: '#185a9d',
        transform: 'translateX(17px)',
        '&:hover': {
          backgroundColor: 'rgba(24,90,257,0.08)',
        },
      },
      '& $thumb': {
        backgroundColor: '#fff',
      },
      '& + $track': {
        background: 'linear-gradient(to right, #34AA44, #34AA44)',
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        },
      },
    },
  };
});

const FormSwitchGreen: React.FC<ISwitch> = (props: ISwitch) => {
  const [field, meta, helpers] = useField(props);
  const { value } = field;
  const { setValue } = helpers;
  const { label, direction, align, name, submitFailed } = props;
  const classes = useStyles();

  const handleChange = (e: any) => {
    setValue(e.target.checked);
  };

  return (
    <>
      <div className={classes.parent}>
        {label && align !== 'left' && (
          <InputLabel shrink style={{ marginTop: '8px', marginLeft: '5px' }}>
            {label}
          </InputLabel>
        )}
        <Switch
          classes={classes}
          checked={value ? true : false}
          {...props}
          onChange={handleChange}
        />
        {label && align === 'left' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            <InputLabel shrink>{label}</InputLabel>
          </div>
        )}
      </div>
      {(submitFailed || meta.touched) && meta.error ? (
        <div className="inputError" data-test-id={`input-error-${name}`}>
          {meta.error}
        </div>
      ) : null}
    </>
  );
};

FormSwitchGreen.defaultProps = {
  direction: 'right',
};

export default FormSwitchGreen;
