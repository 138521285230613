import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Card,
  TableRow,
  TableCell,
  Divider,
  Theme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form } from 'formik';
import FormNumberField from '../../components/FormNumberField';
import agApi from '../../api';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  card: {
    width: 300,
  },
  inner: {
    width: 250,
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
    backgroundColor: '#1976D2',
    color: 'black',
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  tooltip: {
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  },
}));

interface Props {
  className?: Record<string, unknown> | string;
  open: boolean;
  setOpen: any;
  setForexes: any;
}

const EditForex: React.FC<Props> = ({
  className,
  open,
  setOpen,
  setForexes,
  ...rest
}: any) => {
  const classes = useStyles();
  const { mutateAsync: addCurrencies } = agApi('addCurrencies');
  const [submitFailed, setSubmitFailed] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSave = (props: any) => {
    const { validateForm, setTouched, submitForm, values } = props;
    validateForm().then((error: any) => {
      if (Object.keys(error).length === 0) {
        setTouched({});
        setSubmitFailed(false);
      } else {
        setSubmitFailed(true);
      }
      submitForm();
    });
  };

  const initialValues = {
    rmbhkd: 0,
    hkdrmb: 0,
    phphkd: 0,
    hkdphp: 0,
  };

  const handleSubmit = async (values: any) => {
    const result = await addCurrencies(values);
    setForexes(result);
    setOpen(false);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(props: any) => (
          <Form translate="yes">
            <Dialog open={open} onClose={handleCancel}>
              <DialogTitle>{'添加最新汇率'}</DialogTitle>
              <DialogContent>
                <Card {...rest} className={clsx(classes.root, className)}>
                  <Divider />
                  <TableRow key="HKDRMB">
                    <TableCell>港兑人</TableCell>
                    <TableCell>
                      <FormNumberField
                        name="hkdrmb"
                        onChange={props.handleChange}
                        submitFailed={submitFailed}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key="RMBHKD">
                    <TableCell>人兑港</TableCell>
                    <TableCell>
                      <FormNumberField
                        name="rmbhkd"
                        onChange={props.handleChange}
                        submitFailed={submitFailed}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key="HKDPHP">
                    <TableCell>港兑披索</TableCell>
                    <TableCell>
                      <FormNumberField
                        name="hkdphp"
                        onChange={props.handleChange}
                        submitFailed={submitFailed}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key="PHPHKD">
                    <TableCell>披索兑港</TableCell>
                    <TableCell>
                      <FormNumberField
                        name="phphkd"
                        onChange={props.handleChange}
                        submitFailed={submitFailed}
                      />
                    </TableCell>
                  </TableRow>
                </Card>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel} color="primary">
                  取消
                </Button>
                <Button
                  onClick={() => handleSave(props)}
                  color="primary"
                  autoFocus
                >
                  保存
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditForex;
