import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Paper, Button, Input } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  search: {
    flexGrow: 1,
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon,
  },
  searchInput: {
    flexGrow: 1,
  },
  searchButton: {
    marginLeft: theme.spacing(2),
  },
}));

interface Props {
  className?: Record<string, unknown> | string;
  onSearch: () => void;
}

const Search: React.FC<Props> = ({ onSearch, className, ...rest }: any) => {
  const classes = useStyles();
  const [searchStr, setSearchStr] = useState<string>();

  const onChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = event.target;
    setSearchStr(value);
  };

  const handelOnSearch = () => {
    onSearch(searchStr);
  };

  const handelOnEnterSearch = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onSearch(searchStr);
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Paper className={classes.search} elevation={1}>
        <SearchIcon className={classes.searchIcon} />
        <Input
          className={classes.searchInput}
          disableUnderline
          onChange={onChange}
          placeholder=""
          onKeyPress={handelOnEnterSearch}
        />
      </Paper>
      <Button
        className={classes.searchButton}
        onClick={handelOnSearch}
        size="large"
        variant="contained"
      >
        查找
      </Button>
    </div>
  );
};

// Search.propTypes = {
//   className: PropTypes.string,
//   onSearch: PropTypes.func,
// };

export default Search;
