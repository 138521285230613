import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import { Theme } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import agApi from '../../api';
import { JSEncrypt } from 'jsencrypt';
import { CircularProgress } from '@mui/material';
import * as jwt from 'jsonwebtoken';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    // Fix IE 11 issue.
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loginError: {
    color: '#cd2d2d',
    fontSize: '16px',
    margin: '22px 0 10px 5px',
    alignItems: 'left',
    width: '100%',
  },
}));

const LogIn: React.FC = () => {
  const classes = useStyles();
  const history = useNavigate();
  const { data } = agApi('getCurrentUser');
  const {
    mutateAsync: login,
    isSuccess: loginSuccess,
    error: loginError,
  } = agApi('login');
  const [logining, setLogining] = useState(false);

  const initialValues = {
    username: '',
    password: '',
    remember: false,
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .transform((v) => (v === null ? '' : v))
      .required('Required'),
    password: Yup.string()
      .transform((v) => (v === null ? '' : v))
      .required('Required'),
  });

  const handleSubmit = async (values: any) => {
    setLogining(true);
    const { username, password, remember } = values;
    // eslint-disable-next-line camelcase
    const encryptor = new JSEncrypt({ default_key_size: '2048' });
    const pubKey = process.env.REACT_APP_PUBLIC_KEY?.replace(/\\n/g, '\n');
    encryptor.setPublicKey(pubKey);
    const encryptedPassword = encryptor.encrypt(password);
    const postData = {
      username,
      password: encryptedPassword,
      remember,
    };
    try {
      const response = await login(postData);
      if (response?.token) {
        const payload = jwt.decode(response?.token);
        console.log(payload);
        localStorage.setItem('token', response?.token);
      }
    } catch (err) {
      // login failed
    }

    setLogining(false);
  };

  if (loginSuccess || data?.user) {
    history('/reports');
  }

  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {!logining && (
            <>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                用户登录
              </Typography>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={true}
                validateOnChange={true}
                onSubmit={handleSubmit}
              >
                {(formProps: any) => {
                  const { handleChange } = formProps;
                  return (
                    <Form>
                      <TextField
                        name="username"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="用户名"
                        autoFocus
                        onChange={handleChange}
                      />
                      <TextField
                        name="password"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="密码"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleChange}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="remember"
                            color="primary"
                            onChange={handleChange}
                          />
                        }
                        label="自动登录"
                      />
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        登录
                      </Button>
                      {loginError ? (
                        <div className={classes.loginError}>
                          {loginError.message}
                        </div>
                      ) : null}
                    </Form>
                  );
                }}
              </Formik>
            </>
          )}
          {logining && <CircularProgress />}
        </div>
      </Container>
    </>
  );
};

export default LogIn;
