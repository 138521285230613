import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Container,
  Box,
  Button,
  Theme,
  TextField,
  CircularProgress,
  Typography,
  Card,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TableHead,
  TableRow,
  TableCell,
  Autocomplete,
  Grid,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Page from '../../components/Page';
import Header from './Header';
import DisplayRow from './DisplayRow';
import agApi from '../../api';
import Pending from './Pending';
import Completed from './Completed';
import moment from 'moment-timezone';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  pending: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  results: {
    marginTop: theme.spacing(3),
  },
  datetimePicker: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  editButton: {
    textTransform: 'none',
    marginLeft: '10px',
  },
  circularProgress: {
    marginLeft: '20px',
  },
  card: {
    marginTop: 5,
    padding: 20,
  },
  autoCompleteRoot: {
    padding: 0,
  },
  inputRoot: {
    border: '0px',
    borderRadius: '5px',
    borderColor: '#ffffff',
    backgroundColor: '#ffffff',
    marginRight: 20,
  },
  inputError: {
    color: '#cd2d2d',
    fontSize: '16px',
    margin: '22px 0 10px 5px',
    '&:before': {
      content: '',
      display: 'inline-block',
      width: '30px',
      height: '30px',
      backgroundimage: "url('../../asset/img/error.svg')",
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      marginRight: '15px',
      marginBottom: '-6px',
    },
  },
}));

const Downloading = () => {
  const classes = useStyles();
  return (
    <Box
      position="relative"
      display="inline-flex"
      className={classes.circularProgress}
      style={{
        marginTop: 20,
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
      }}
    >
      <CircularProgress />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          处理中
        </Typography>
      </Box>
    </Box>
  );
};

const UpdownManage: React.FC = () => {
  const classes = useStyles();
  const { mutateAsync: getAgents } = agApi('getAgents');
  const { mutateAsync: getAllUpAgcodes } = agApi('getAllUpAgcodes');
  const { mutateAsync: deposit } = agApi('deposit');
  const { mutateAsync: withdraw } = agApi('withdraw');
  const [actionType, setActionType] = useState('deposit');
  const { mutateAsync: getUpDownPending } = agApi('getUpDownPending');
  const { mutateAsync: getUpDownCompleted } = agApi('getUpDownCompleted');
  const [pendingData, setPendingData] = useState<any>([]);
  const [completedData, setCompletedData] = useState<any>([]);

  const handleActionTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setActionType(event.target.value);
  };

  const inputAgent = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [agents, setAgents] = useState([{}]);
  const [inputAgentError, setInputAgentError] = useState<undefined | boolean>(
    undefined
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [allAgcodes, setAllAgcodes] = useState([{}]);
  const [agcode, setAgcode] = useState();
  const [upAmount, setUpAmount] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [downloading, setDownloading] = useState(false);

  const formatData = (data: any) => {
    const newData = data.map((row: any) => {
      return {
        id: row.updownId,
        createdAt: moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        createdBy: row.createdByUsername,
        approvedAt: row.approvedAt
          ? moment(row.approvedAt).format('YYYY-MM-DD HH:mm:ss')
          : '',
        approvedBy: row.approvedByUsername,
        cgAccount: row.cgAccount,
        action:
          row.action === 'deposit'
            ? '上分'
            : row.action === 'withdraw'
            ? '下分'
            : '',
        amount: row.amount,
      };
    });
    return newData;
  };

  const refreshData = async () => {
    const data1 = await getUpDownPending();
    setPendingData(formatData(data1));
    const data2 = await getUpDownCompleted();
    setCompletedData(formatData(data2));
  };

  useEffect(() => {
    let mounted = true;

    const fetchAgents = () => {
      getAgents().then((data: any) => {
        if (mounted) {
          setAgents(data);
        }
      });
    };

    fetchAgents();

    refreshData();

    return () => {
      mounted = false;
    };
  }, []);
  const onUpDown = async () => {
    setProcessing(true);
    setInputAgentError(false);

    const allUpAgcodes = await getAllUpAgcodes({ agcode });
    setAllAgcodes(allUpAgcodes);

    setProcessing(false);
    setOpenDialog(true);
  };

  const handleChangeAgent = (event: any, newValue: any) => {
    setAgcode(newValue.agcode);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const onConfirm = async () => {
    setErrorMessage('');
    setDownloading(true);
    try {
      if (actionType === 'deposit') {
        const data = {
          agcode,
          amount: upAmount,
        };
        const result = await deposit(data);
        if (result === 'pending') {
          setErrorMessage('上分数额大，待审核');
        } else {
          setErrorMessage('上分成功');
        }
      } else {
        const data = {
          agcode,
          amount: upAmount,
        };
        const result = await withdraw(data);
        if (result === 'pending') {
          setErrorMessage('下分数额大，待审核');
        } else {
          setErrorMessage('下分成功');
        }
      }
      refreshData();
    } catch (err) {
      setErrorMessage('操作失败:' + err.message);
    }

    setOpenDialog(false);
    setDownloading(false);
  };

  const handleAmountChange = (event: any) => {
    const amount = event.target.value;
    setUpAmount(amount);
  };

  return (
    <>
      <Page className={classes.root} title="结算">
        <Container maxWidth={false}>
          <Header />
          <Card className={classes.card}>
            <Grid container spacing={2} mt={0}>
              <Box>
                <Autocomplete
                  freeSolo
                  options={agents}
                  getOptionLabel={(option: any) =>
                    option.cgAccount ? option.cgAccount : ''
                  }
                  disableClearable
                  classes={{
                    root: classes.autoCompleteRoot,
                    inputRoot: classes.inputRoot,
                  }}
                  style={{ marginTop: 20 }}
                  autoHighlight={true}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant={'outlined'}
                      placeholder={'输入cg代码'}
                      inputRef={inputAgent}
                      InputProps={{ ...params.InputProps, type: 'search' }}
                      style={{ width: 150 }}
                    />
                  )}
                  onChange={handleChangeAgent}
                />
                {inputAgentError ? (
                  <div className={classes.inputError}>请输入正确的CG户口</div>
                ) : null}
              </Box>
              <TextField
                variant={'outlined'}
                placeholder={'输入分值'}
                style={{ width: 120, marginLeft: 20, marginTop: 20 }}
                onChange={handleAmountChange}
              />
              <FormControl style={{ marginLeft: 20 }}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={actionType}
                  onChange={handleActionTypeChange}
                >
                  <FormControlLabel
                    value="deposit"
                    control={<Radio />}
                    label="上分"
                  />
                  <FormControlLabel
                    value="withdraw"
                    control={<Radio />}
                    label="下分"
                  />
                </RadioGroup>
              </FormControl>
              {!processing ? (
                <Button
                  color="secondary"
                  className={classes.editButton}
                  variant="contained"
                  onClick={onUpDown}
                  style={{ marginLeft: 20, height: 50, marginTop: 20 }}
                >
                  确定
                </Button>
              ) : (
                <Downloading />
              )}
            </Grid>
            <span style={{ color: 'red' }}>
              {errorMessage ? errorMessage : ''}
            </span>
          </Card>
          <Card className={classes.card}>
            <Typography variant="h5" component="div" color="textSecondary">
              待审核大额上下分
            </Typography>
            {pendingData && (
              <Pending data={pendingData} refreshData={refreshData} />
            )}
          </Card>
          <Card className={classes.card}>
            <Typography variant="h5" component="div" color="textSecondary">
              已完成上下分列表
            </Typography>
            {completedData && <Completed data={completedData} />}
          </Card>
        </Container>
      </Page>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        fullWidth
        // maxWidth="lg"
      >
        <DialogTitle>上下分代理列表</DialogTitle>

        <DialogContent>
          <TableHead>
            <TableRow>
              <TableCell>CG户口</TableCell>
              <TableCell>分值</TableCell>
              <TableCell>类型</TableCell>
            </TableRow>
          </TableHead>
          {Object.keys(allAgcodes).length > 0
            ? allAgcodes
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((agentData: any, index: number) => (
                  <DisplayRow
                    key={'settlement_' + index}
                    agentData={agentData}
                    upAmount={upAmount}
                    actionType={actionType}
                  />
                ))
            : '没有上下分数据'}
        </DialogContent>
        {Object.keys(allAgcodes).length > 0 ? (
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              variant="contained"
              color="primary"
            >
              取消
            </Button>
            {!downloading ? (
              <Button onClick={onConfirm} variant="contained" color="primary">
                确定
              </Button>
            ) : (
              <Downloading />
            )}
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              variant="contained"
              color="primary"
            >
              取消
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default UpdownManage;
