import { createTheme, Theme } from '@mui/material/styles';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import { zhCN } from '@mui/material/locale';

const baseTheme: any = {
  palette,
  // typography,
  overrides,
};

export const theme = createTheme(baseTheme, zhCN);
