import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useField } from 'formik';
import FormTitle from './FormTitle';
import NativeSelect from '@mui/material/NativeSelect';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IFormSelectBox {
  name: string;
  defaultValue?: string;
  optionsName?: string;
  options?: any[];
  title?: string;
  tipTitle?: string;
  placeholder?: string;
  fullWidth?: boolean;
  onChange: any;
  required?: boolean;
  shrinkTitle?: boolean;
  submitFailed?: boolean;
  disabledPlaceHolder?: boolean;
}

const useStyles = makeStyles((theme: any) => {
  return {
    root: { paddingRight: 20 },
    iconStyle: {
      marginLeft: -30,
      marginTop: 7,
      paddingBottom: 5,
      pointerEvents: 'none',
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
      },
    },
    disabledOption: {
      '& select': {
        color: 'rgba(0, 0, 0, 0.26)',
      },
    },
    normalOption: {
      color: '#000',
    },
  };
});

const FormNativeSelect: React.FC<IFormSelectBox> = (props: IFormSelectBox) => {
  const [field, meta] = useField(props);
  const { name, value } = field;
  const {
    defaultValue,
    options,
    title,
    tipTitle,
    placeholder,
    onChange,
    required,
    shrinkTitle,
    submitFailed,
    disabledPlaceHolder,
  } = props;

  const classes: any = useStyles();

  return (
    <Grid
      container
      direction="column"
      className={
        disabledPlaceHolder && value === undefined
          ? classes.disabledOption
          : null
      }
    >
      {(title || tipTitle) && (
        <Grid item>
          <FormTitle
            title={title}
            tipTitle={tipTitle}
            shrinkTitle={shrinkTitle ? true : false}
          />
        </Grid>
      )}
      <Grid item>
        <NativeSelect
          required={required}
          key={name + '_NativeSelect'}
          fullWidth
          defaultValue={defaultValue}
          IconComponent={() => <ExpandMoreIcon className={classes.iconStyle} />}
          name={name}
          value={value}
          onChange={onChange}
          inputProps={{
            name,
          }}
          data-test-id={name}
        >
          {placeholder && (
            <option key="placeholder" value="" disabled>
              {placeholder}
            </option>
          )}
          {options &&
            options.map((option: any, i: any) => (
              <option
                key={`${option.value}-${i}`}
                value={option.value}
                data-test-id={`${name}-${option.value}`}
                className={
                  disabledPlaceHolder && value === undefined
                    ? classes.normalOption
                    : null
                }
              >
                {option.label}
              </option>
            ))}
        </NativeSelect>
        {submitFailed && meta.error ? (
          <div className="inputError" data-test-id={`input-error-${name}`}>
            {meta.error}
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default FormNativeSelect;
