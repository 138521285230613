import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {},
}));

const Header: React.FC = ({ className, ...rest }: any) => {
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="flex-end" container spacing={3}>
        <Grid item>
          <Typography component="h1" variant="h3">
            今日汇率
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;
