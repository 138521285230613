import moment from 'moment-timezone';
const ExcelJS = require('exceljs');

const generateExcelFile = async (params: any) => {
  const { configs, data } = params;

  console.log(configs, data);
  let withdrawFee = 0;
  configs &&
    configs.map((config: any) => {
      if (config.name === 'withdrawFee') {
        withdrawFee = Number(config.value);
      }
    });
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('总表');
  worksheet.columns = [
    { header: '匹配类型', key: 'mc', width: 13 },
    { header: '匹配代理占成\n(配码才需要)', key: 'agentPercent', width: 13 },
    { header: '户名', key: 'cqAccount', width: 13 },
    { header: '代理户口', key: 'agentName', width: 13 },
    { header: 'CG户口', key: 'cgAccount', width: 13 },
    { header: 'CG号码', key: 'agcode', width: 13 },
    { header: '类型', key: 'action', width: 13 },
    { header: '操作人', key: 'operator', width: 13 },
    {
      header: '创建时间',
      key: 'createdAt',
      width: 13,
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E2EFDA' },
        bgColor: { argb: 'E2EFDA' },
      },
    },
    { header: '币种', key: 'ccy', width: 13 },
    {
      header: '上下分',
      key: 'amount',
      width: 13,
      style: { numFmt: 'General;[Red]-General' },
    },
    {
      header: `手续费${withdrawFee}%`,
      key: 'handlingFee',
      width: 13,
      style: { numFmt: 'General;[Red]-General' },
    },
    {
      header: '预支',
      key: 'preWithdraw',
      width: 13,
      style: { numFmt: 'General;[Red]-General' },
    },
    {
      header: '实际出入款',
      key: 'actualSettle',
      width: 13,
      style: { numFmt: 'General;[Red]-General' },
    },
    {
      header: '备注',
      key: 'comment',
      width: 13,
    },
    {
      header: '出入款户口',
      key: 'transferAccount',
      width: 13,
    },
  ];
  worksheet.getRow(1).height = 30;
  [
    'A1',
    'B1',
    'C1',
    'D1',
    'E1',
    'F1',
    'G1',
    'H1',
    'I1',
    'J1',
    'K1',
    'L1',
    'M1',
    'N1',
    'O1',
    'P1',
  ].map((key) => {
    worksheet.getCell(key).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D9D9D9' },
      bgColor: { argb: 'D9D9D9' },
    };
    worksheet.getCell(key).border = {
      top: { style: 'medium' },
      left: { style: 'medium' },
      bottom: { style: 'medium' },
      right: { style: 'medium' },
    };
  });

  const startRowNo = 2;
  const emptyRowCount = 0;
  for (let i = 0; i < data.length; i++) {
    const row = data[i];
    const rowNo = i + 2 + emptyRowCount;

    const convertedRow = {
      mc: row['mc'],
      agentPercent: row['mc'] === '配码上分' ? row['agentPercent'] : '',
      createdAt: moment(row['createdAt']).format('YYYY-MM-DD HH:mm:ss'),
      cqAccount: row['cqAccount'],
      agentName: row['agentName'],
      cgAccount: row['cgAccount'],
      agcode: row['agcode'],
      action: row['action'],
      operator: row['operator'],
      ccy: row['ccy'],
      amount: row['amount'],
      handlingFee: row['handlingFee'],
      preWithdraw: row['preWithdraw'],
      actualSettle: Number((row['actualSettle'] / 10000).toFixed(4)),
      comment: row['comment'],
      transferAccount: row['transferAccount'],
    };

    const addedRow = worksheet.addRow(convertedRow);
    const fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'f4f7c8' },
      bgColor: { argb: 'f4f7c8' },
    };
    const border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    const arr = Array.from({ length: 16 }, (_, j) => j + 1);
    arr.map((x) => (worksheet.getCell(worksheet.rowCount, x).fill = fill));
    arr.map((x) => (worksheet.getCell(worksheet.rowCount, x).border = border));
  }

  const count = data.length;
  const sumRowNo = count + 1 + emptyRowCount;

  const sumRow = {
    ccy: '合计:',
    amount: { formula: `SUM(K${startRowNo}:K${sumRowNo})` },
    handlingFee: { formula: `SUM(L${startRowNo}:L${sumRowNo})` },
    actualSettle: { formula: `SUM(N${startRowNo}:N${sumRowNo})` },
  };
  const addedSum = worksheet.addRow(sumRow);

  // write to a new buffer
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer]);
  const objectURL = URL.createObjectURL(blob);
  // Auto download
  const link = document.createElement('a');
  link.href = objectURL;
  link.setAttribute('download', '上下分记录.xlsx');
  // Append to html link element page
  document.body.appendChild(link);
  // Start download
  link.click();
  // Clean up and remove the link
  link?.parentNode.removeChild(link);
};

export default generateExcelFile;
