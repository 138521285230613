import React from 'react';
import { TextField, Grid, InputAdornment } from '@mui/material';
import { useField } from 'formik';
import FormTitle from './FormTitle';

interface ITextField {
  name: string;
  value?: string;
  title?: string;
  label?: string;
  prefix?: string;
  placeholder?: string;
  fullWidth?: boolean;
  tipTitle?: string;
  type?: string;
  inputProps?: unknown;
  onBlur?: () => void;
  onChange?: () => void;
  shrink?: boolean;
  shrinkTitle?: boolean;
  submitFailed?: boolean;
}

const FormTextField: React.FC<ITextField> = (props: ITextField) => {
  const [field, meta] = useField(props);
  const { value } = field;
  const {
    title,
    tipTitle,
    prefix,
    type,
    inputProps,
    shrink,
    shrinkTitle,
    submitFailed,
    name,
  } = props;

  return (
    <Grid container direction="column">
      {title && (
        <Grid item>
          <FormTitle
            title={title}
            tipTitle={tipTitle}
            shrinkTitle={shrinkTitle ? true : false}
          />
        </Grid>
      )}
      <Grid item>
        <TextField
          {...field}
          {...props}
          type={type}
          error={meta.touched && meta.error ? true : false}
          id="outlined-helperText"
          variant="outlined"
          InputProps={{
            ...(inputProps as any),
            startAdornment: prefix && (
              <InputAdornment position="start">$</InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: value || shrink ? true : false,
          }}
        />
        {(submitFailed || meta.touched) && meta.error ? (
          <div className="inputError" data-test-id={`input-error-${name}`}>
            {meta.error}
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
};

FormTextField.defaultProps = {
  type: 'text',
};

export default FormTextField;
