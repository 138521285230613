/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Container,
  Typography,
  Card,
  Divider,
  CardContent,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Button,
  colors,
  Theme,
} from '@mui/material';
import Page from '../../components/Page';
import { useParams } from 'react-router';
import { Formik, Form } from 'formik';
import FormTextField from '../../components/FormTextField';
import agApi from '../../api';
import { JSEncrypt } from 'jsencrypt';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  divider: {
    backgroundColor: colors.grey[300],
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(2),
  },
  section: {
    '& + &': {
      marginTop: theme.spacing(5),
    },
  },
  tab: {
    textTransform: 'none',
    minWidth: 36,
  },
  buttonSpacing: {
    marginRight: 10,
  },
  table: {
    width: '400px',
  },
  editButton: {
    textTransform: 'none',
  },
  editAddBox: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  editButtonBox: {
    marginLeft: '0',
    marginTop: 10,
  },
  addButtonBox: {
    marginLeft: 'auto',
    marginRight: 0,
    marginTop: 10,
  },
}));

const Agent: React.FC = () => {
  const classes = useStyles();
  const { mutateAsync: changePassword } = agApi('changePassword');
  const { username } = useParams<any>();
  const [submitFailed, setSubmitFailed] = useState(false);
  const [message, setMessage] = useState('');

  const handleSave = (props: any) => {
    const { validateForm, setTouched, submitForm } = props;
    validateForm().then((error: any) => {
      if (Object.keys(error).length === 0) {
        setTouched({});
        setSubmitFailed(false);
      } else {
        setSubmitFailed(true);
      }
      submitForm();
    });
  };

  const initialValues = {
    oldPassword: '',
    newPassword: '',
  };

  const handleSubmit = async (values: any) => {
    const encryptor = new JSEncrypt({ default_key_size: '2048' });
    const pubKey = process.env.REACT_APP_PUBLIC_KEY?.replace(/\\n/g, '\n');
    encryptor.setPublicKey(pubKey);
    const encryptedOldPassword = encryptor.encrypt(values.oldPassword);
    const encryptedNewPassword = encryptor.encrypt(values.newPassword);

    const postData = {
      username,
      encryptedOldPassword,
      encryptedNewPassword,
    };
    try {
      const result = await changePassword(postData);
      setMessage('密码修改成功！');
    } catch (err) {
      console.log(err);
      setMessage('密码修改失败: ' + err.message);
    }
  };

  return (
    <>
      <Page className={classes.root} title="Agents Management">
        <Container maxWidth={false}>
          <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {(props: any) => {
                return (
                  <Form translate="yes">
                    <Card>
                      <Divider />
                      <TableRow>
                        <TableCell>旧密码</TableCell>
                        <TableCell>
                          <FormTextField
                            name="oldPassword"
                            onChange={props.handleChange}
                            submitFailed={submitFailed}
                            type="password"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>新密码</TableCell>
                        <TableCell>
                          <FormTextField
                            name="newPassword"
                            onChange={props.handleChange}
                            submitFailed={submitFailed}
                            type="password"
                          />
                        </TableCell>
                      </TableRow>
                      <span style={{ color: 'red' }}>{message}</span>
                      <TableRow>
                        <TableCell />
                        <TableCell>
                          <Button
                            onClick={() => handleSave(props)}
                            color="primary"
                          >
                            确定修改
                          </Button>
                        </TableCell>
                      </TableRow>
                    </Card>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Container>
      </Page>
    </>
  );
};

export default Agent;
