import React, { useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

const columns: GridColDef[] = [
  {
    headerName: '户口',
    field: 'cqAccount',
  },
  {
    headerName: 'CG户口',
    field: 'cgAccount',
  },
  {
    headerName: '种类',
    field: 'mc',
    align: 'center',
    width: 60,
  },
  {
    headerName: '代理占成',
    field: 'agentPercent',
    align: 'right',
  },
  {
    headerName: '代理佣金比',
    field: 'agentCommission',
    align: 'right',
  },
  {
    headerName: '股东佣金差',
    field: 'commissionDiff',
    align: 'right',
  },
  {
    headerName: '总余分',
    field: 'totalBalance',
    align: 'right',
    type: 'number',
  },
  {
    headerName: '可用余分',
    field: 'balance',
    align: 'right',
    type: 'number',
  },
  {
    headerName: '下线余分',
    field: 'memberBalance',
    align: 'right',
    type: 'number',
  },
  {
    headerName: '现金余分',
    field: 'cashBalance',
    align: 'right',
    type: 'number',
  },
];

const AgentList: React.FC<any> = (props: any) => {
  const [pageSize, setPageSize] = useState(10);
  const history = useNavigate();

  return (
    <div style={{ height: 560, width: '100%' }}>
      <DataGrid
        rows={props.data}
        columns={columns}
        rowHeight={45}
        rowsPerPageOptions={[10, 25, 50, 100]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
        onRowClick={(params, event) => {
          history('/agent/' + params.id);
        }}
        localeText={{
          noRowsLabel: '没有数据。',
          noResultsOverlayLabel: '未找到数据。',
          errorOverlayDefaultLabel: '发生错误。',

          // Density selector toolbar button text
          toolbarDensity: '表格密度',
          toolbarDensityLabel: '表格密度',
          toolbarDensityCompact: '紧密',
          toolbarDensityStandard: '标准',
          toolbarDensityComfortable: '稀疏',

          // Columns selector toolbar button text
          toolbarColumns: '列',
          toolbarColumnsLabel: '选择列',

          // Filters toolbar button text
          toolbarFilters: '筛选器',
          toolbarFiltersLabel: '显示筛选器',
          toolbarFiltersTooltipHide: '隐藏筛选器',
          toolbarFiltersTooltipShow: '显示筛选器',
          toolbarFiltersTooltipActive: (count: number) => `${count} 个筛选器`,

          // Export selector toolbar button text
          toolbarExport: '导出',
          toolbarExportLabel: '导出',
          toolbarExportCSV: '导出至CSV',
          // toolbarExportPrint: 'Print',

          // Columns panel text
          columnsPanelTextFieldLabel: '搜索列',
          columnsPanelTextFieldPlaceholder: '列名',
          columnsPanelDragIconLabel: '重排序列',
          columnsPanelShowAllButton: '显示所有',
          columnsPanelHideAllButton: '隐藏所有',

          // Filter panel text
          filterPanelAddFilter: '添加筛选器',
          filterPanelDeleteIconLabel: '删除',
          filterPanelOperators: '操作器',
          filterPanelOperatorAnd: '与',
          filterPanelOperatorOr: '或',
          filterPanelColumns: '列',
          filterPanelInputLabel: '值',
          filterPanelInputPlaceholder: '筛选值',

          // Filter operators text
          filterOperatorContains: '包含',
          filterOperatorEquals: '等于',
          filterOperatorStartsWith: '开始于',
          filterOperatorEndsWith: '结束于',
          filterOperatorIs: '是',
          filterOperatorNot: '不是',
          filterOperatorAfter: '在后面',
          filterOperatorOnOrAfter: '正在后面',
          filterOperatorBefore: '在前面',
          filterOperatorOnOrBefore: '正在前面',
          filterOperatorIsEmpty: '为空',
          filterOperatorIsNotEmpty: '不为空',
          // filterOperatorIsAnyOf: 'is any of',

          // Filter values text
          filterValueAny: '任何',
          filterValueTrue: '真',
          filterValueFalse: '假',

          // Column menu text
          columnMenuLabel: '菜单',
          columnMenuShowColumns: '显示',
          columnMenuFilter: '筛选器',
          columnMenuHideColumn: '隐藏',
          columnMenuUnsort: '恢复默认',
          columnMenuSortAsc: '升序',
          columnMenuSortDesc: '降序',

          // Column header text
          columnHeaderFiltersTooltipActive: (count: number) =>
            count !== 1 ? `${count} 个筛选器` : `${count} 个筛选器`,
          columnHeaderFiltersLabel: '显示筛选器',
          columnHeaderSortIconLabel: '排序',

          // Rows selected footer text
          footerRowSelected: (count: number) =>
            `共选中了${count.toLocaleString()}行`,

          // Total rows footer text
          footerTotalRows: '所有行:',

          // Total visible rows footer text
          footerTotalVisibleRows: (visibleCount, totalCount) =>
            `${visibleCount.toLocaleString()} / ${totalCount.toLocaleString()}`,

          // Checkbox selection text
          checkboxSelectionHeaderName: '多选框',
          // checkboxSelectionSelectAllRows: 'Select all rows',
          // checkboxSelectionUnselectAllRows: 'Unselect all rows',
          // checkboxSelectionSelectRow: 'Select row',
          // checkboxSelectionUnselectRow: 'Unselect row',

          // Boolean cell text
          booleanCellTrueLabel: '真',
          booleanCellFalseLabel: '假',

          // Actions cell more text
          // actionsCellMore: 'more',

          // Column pinning text
          // pinToLeft: 'Pin to left',
          // pinToRight: 'Pin to right',
          // unpin: 'Unpin',

          // Tree Data
          // treeDataGroupingHeaderName: 'Group',
          // treeDataExpand: 'see children',
          // treeDataCollapse: 'hide children',

          // Grouping columns
          // groupingColumnHeaderName: 'Group',
          // groupColumn: name => `Group by ${name}`,
          // unGroupColumn: name => `Stop grouping by ${name}`,

          // Master/detail
          // expandDetailPanel: 'Expand',
          // collapseDetailPanel: 'Collapse',
        }}
      />
    </div>
  );
};

export default AgentList;
