import {
  Routes as Switch,
  Route,
  Navigate,
  BrowserRouter,
} from 'react-router-dom';
import AuthRoute from './auth-route';
import PrivateRoute from './private-route';
import Error404 from './error404';
import Forex from '../pages/forex';
import Updown from '../pages/updown';
import Reports from '../pages/reports';
import Agents from '../pages/agents';
import Agent from '../pages/agent';
import Settle from '../pages/settle';
import Cash from '../pages/cash';
import Audit from '../pages/audit';
import Profile from '../pages/profile';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login" element={<AuthRoute />} />
        <Route
          path="/forex"
          element={
            <PrivateRoute>
              <Forex />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<Navigate to="/reports" />} />
        <Route
          path="/updown"
          element={
            <PrivateRoute>
              <Updown />
            </PrivateRoute>
          }
        />
        <Route
          path="/reports"
          element={
            <PrivateRoute>
              <Reports />
            </PrivateRoute>
          }
        />
        <Route
          path="/agents"
          element={
            <PrivateRoute>
              <Agents />
            </PrivateRoute>
          }
        />
        <Route
          path="/agent/:id"
          element={
            <PrivateRoute>
              <Agent />
            </PrivateRoute>
          }
        />
        <Route
          path="/settle"
          element={
            <PrivateRoute>
              <Settle />
            </PrivateRoute>
          }
        />
        <Route
          path="/cash"
          element={
            <PrivateRoute>
              <Cash />
            </PrivateRoute>
          }
        />
        <Route
          path="/audit"
          element={
            <PrivateRoute>
              <Audit />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/:username"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route element={<Error404 />} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
