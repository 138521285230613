import BarChartIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FactCheckIcon from '@mui/icons-material/FactCheck';

export default [
  {
    subheader: '',
    items: [
      // {
      //   title: '汇率',
      //   href: '/forex',
      //   icon: ShowChartIcon,
      // },
      {
        title: '代理管理',
        href: '/agents',
        icon: AccountBoxIcon,
      },
      {
        title: '上下分',
        href: '/updown',
        icon: AttachMoneyIcon,
      },
      {
        title: '报表',
        href: '/reports',
        icon: BarChartIcon,
      },
      {
        title: '结算',
        href: '/settle',
        icon: MoneyOffIcon,
      },
      {
        title: '现金表',
        href: '/cash',
        icon: AttachMoneyIcon,
      },
      {
        title: '核数表',
        href: '/audit',
        icon: FactCheckIcon,
      },
    ],
  },
];
